import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Config } from '@configs/config';

@Pipe({
  name: 'customCurrency',
  standalone: true,
})
export class CustomCurrencyPipe implements PipeTransform {
  constructor(private currency: CurrencyPipe) {}
  transform(price: number): string {
    return (
      this.currency.transform(
        price,
        Config.DEFAULT_CURRENCY_FORMAT.CurrencyCode,
        Config.DEFAULT_CURRENCY_FORMAT.Display,
        Config.DEFAULT_CURRENCY_FORMAT.DigitsInfo,
        Config.DEFAULT_CURRENCY_LOCALE,
      ) || '0 PLN'
    );
  }
}
