import { ScreenOutput } from '@graphql/graphql-types';
import { ScreenGroup } from './screen-group-second.model';
import { ScreenRow } from './screen-row.model';
import { ScreenSeat } from './screen-seat.model';
import { ScreenElement } from './screen-element.model';
import { ScreenColumn } from './screen-column.model';
import { ScreenBlockedSeat } from './screen-blocked-seat.model';

export class Screen implements ScreenOutput {
  blockedList?: ScreenBlockedSeat[];
  cols?: ScreenColumn[];
  defaultSeatDescription?: string;
  defaultSeatGroupName?: string;
  feature?: string;
  ffaNumber?: string;
  groups?: ScreenGroup[];
  id?: string;
  name?: string;
  number?: number;
  proCinema?: string;
  rows?: ScreenRow[];
  screenElements?: ScreenElement[];
  screenGroupId?: string;
  seats?: ScreenSeat[];
  type?: string;

  constructor(data: Screen | ScreenOutput) {
    data
      ? Object.assign(this, data, {
          blockedList: data.blockedList ? data.blockedList.map((o) => new ScreenBlockedSeat(o)) : [],
          cols: data.cols ? data.cols.map((o) => new ScreenColumn(o)) : [],
          groups: data.groups ? data.groups.map((o) => new ScreenGroup(o)) : [],
          rows: data.rows ? data.rows.map((o) => new ScreenRow(o)) : [],
          screenElements: data.screenElements ? data.screenElements.map((o) => new ScreenElement(o)) : [],
          seats: data.seats ? data.seats.map((o) => new ScreenSeat(o)) : [],
        })
      : '';
  }
}
