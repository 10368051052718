import { SoundType } from '@enums/sound-type.enum';
import { AvailabilityStatus, ScreeningOutput } from '@graphql/graphql-types';
import { Movie } from './movie.model';
import { Screen } from './screen.model';

export class Screening implements ScreeningOutput {
  audience!: number;
  availabilityStatus!: AvailabilityStatus;
  cinemaId!: string;
  format?: string;
  frameRate?: string;
  generalAdmission!: boolean;
  id!: string;
  isScreenSwapping!: boolean;
  language!: string;
  maxOccupancy!: number;
  movie!: Movie;
  movieExternalId!: string;
  movieId!: string;
  moviePrintExternalId!: string;
  moviePrintId!: string;
  printType!: string;
  release!: string;
  reservationTimeTo: any;
  resolution?: string;
  restrictedToCardTypeIds!: string[];
  saleTimeTo: any;
  screenId!: string;
  screeningDuration?: number;
  screeningTimeFrom: any;
  screeningTimeTo: any;
  soundType!: SoundType;
  speakingType!: string;
  subtitles!: string;
  subtitles2!: string;
  screen?: Screen;

  constructor(data: Screening | ScreeningOutput) {
    data ? Object.assign(this, data, { movie: new Movie(data.movie!), screen: new Screen(data.screen!) }) : '';
  }
}
