import { EditOrderFragment } from '@graphql/fragments/reservation/edit-order.fragment';
import { gql } from 'apollo-angular';

export const EditOrderDocument = gql`
  mutation editOrder($orderId: String!, $orderEntries: [OrderEditEntryInput!]!) {
    editOrder(orderId: $orderId, orderEntries: $orderEntries) {
      ...EditOrder
    }
  }
  ${EditOrderFragment}
`;
