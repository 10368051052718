import { OrderEditUserDataInput, ScreeningItemOutput } from '@graphql/graphql-types';
import { Ticket } from '@models/ticket.model';

export class ReservationOrder {
  orderId!: string;
  screeningId!: string;
  movieId!: string;
  screeningItems!: ScreeningItemOutput[];
  dateTimeToLive!: string;

  constructor(order: ReservationOrder) {
    order ? Object.assign(this, order) : '';
  }
}

export class Reservation {
  started?: boolean;
  remainingTime?: string;
  endSession?: boolean;
  order?: ReservationOrder | null;
  tickets!: Ticket[][];
  vouchers!: AppliedVoucher[] | null;
  screeningTimeFrom?: string | null;
  movieTitle?: string | null;
  movieOriginalTitle?: string | null;
  screenRoomNumber?: number | null;
  orderUserData?: OrderEditUserDataInput | null;
  paymentId?: string | null;
}

export class AppliedVoucher {
  voucherNumber!: string;
  row!: string;
  seat!: string;
  screeningItemId!: string;
  price!: number;
  seatId!: string;
}
