import { ColumnOutput } from '@graphql/graphql-types';

export class ScreenColumn implements ColumnOutput {
  coordinate?: number;
  id?: string;

  constructor(data: ScreenColumn | ColumnOutput) {
    data ? Object.assign(this, data) : '';
  }
}
