import { TicketExtraFeeOutput, TicketOutput } from '@graphql/graphql-types';

export class Ticket implements TicketOutput {
  defaultPriceLevelPrice?: number;
  extraFees?: TicketExtraFeeOutput[];
  id?: string;
  name?: string;
  price?: number;
  priceWithMandatoryExtraFees?: number;
  screeningItemId?: string;
  seatId?: string;
  tax?: number;
  isSelected?: boolean;
  tempId?: string;
  hasVoucher?: boolean;

  constructor(data?: Ticket | TicketOutput) {
    data ? Object.assign(this, data, { hasVoucher: false }) : '';
  }
}
