import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'kg-movie-overlay',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="movie-overlay" [class.is-order-page]="isOrderPage" [class.is-list]="isList">
      <div class="gradient"></div>
      <div class="poster" [style.background-image]="'url(' + poster + ')'"></div>
      <div class="bottom-gradient"></div>
    </div>
  `,
  styleUrls: ['./movie-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MovieOverlayComponent {
  @Input() poster!: string;
  @Input() isOrderPage = false;
  @Input() isList = false;
}
