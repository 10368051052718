import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'screening',
  },
  {
    path: 'screening',
    loadChildren: () => import('@pages/screening/screening.module').then((m) => m.ScreeningModule),
  },
  {
    path: 'reservation',
    loadChildren: () => import('@pages/reservation/reservation.module').then((m) => m.ReservationModule),
  },
  { path: 'payment', loadChildren: () => import('@pages/payment/payment.module').then((m) => m.PaymentModule) },
  {
    path: 'technical-break',
    loadComponent: () =>
      import('@pages/technical-break/technical-break.component').then((m) => m.TechnicalBreakComponent),
  },
  {
    path: '**',
    loadComponent: () => import('@pages/not-found/not-found.component').then((m) => m.NotFoundComponent),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
