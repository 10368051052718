import { gql } from 'apollo-angular';

export const SeatFragment = gql`
  fragment Seat on SeatOutput {
    colId
    groupId
    id
    kind
    rowId
    symbol
    wheelchairSeat
  }
`;
