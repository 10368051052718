import { Injectable } from '@angular/core';
import { StorageKey } from '@enums/storage-key.enum';
import { JWT } from '@interfaces/jwt.interface';
import { LocalStorageService } from '@services/local-storage.service';

const BASE_TOKEN_LIFESPAN: number = 1000 * 60 * 60 * 24 * 28; // 4 WEEKS

@Injectable({
  providedIn: 'root',
})
export class AuthStorageService {
  constructor(private readonly storageService: LocalStorageService) {}

  get jwt(): string | null {
    const jwtStr = this.storageService.get(StorageKey.JWT);
    if (!jwtStr) return null;

    const jwt = JSON.parse(jwtStr);
    const now = Date.now();
    if (now > jwt.expire) {
      this.storageService.delete(StorageKey.JWT);
      return null;
    }
    return jwt.token;
  }

  set jwt(jwt: string | null) {
    this.storageService.put(
      StorageKey.JWT,
      JSON.stringify({
        token: jwt,
        expire: Date.now() + BASE_TOKEN_LIFESPAN,
      }),
    );
  }

  get isLogged(): boolean {
    return !!this.jwt?.toString().length;
  }

  setSignedIn(login: JWT): void {
    this.jwt = login.token;
  }

  deleteJWT(): void {
    this.storageService.delete(StorageKey.JWT);
  }

  clear(): void {
    this.storageService.clear();
  }
}
