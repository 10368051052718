import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'kg-card-iconed',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="card-iconed">
      <div class="card-iconed-icon">
        <ng-content select="[icon]"></ng-content>
      </div>
      <div class="card-iconed-content">
        <div class="card-iconed-title">
          <ng-content select="[title]"></ng-content>
        </div>
        <div class="card-iconed-body">
          <ng-content select="[body]"></ng-content>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./card-with-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardWithIconComponent {}
