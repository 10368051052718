import { Injectable } from '@angular/core';
import { Langauge } from '@enums/lang.enum';
import { environment } from '@env';

export const LANGUAGE_KEY = 'language';
export const SCREENING_URL_KEY = 'screening-url';
export const COOKIE_KEY = 'cookies-accepted';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  get(key: string): string | null {
    return localStorage.getItem(`${environment.localStoragePrefix}${key}`);
  }

  put(key: string, val: any): void {
    localStorage.setItem(`${environment.localStoragePrefix}${key}`, val);
  }

  delete(key: string): void {
    localStorage.removeItem(`${environment.localStoragePrefix}${key}`);
  }

  clear(): void {
    localStorage.clear();
  }

  setLang(lang: Langauge): void {
    this.put(LANGUAGE_KEY, lang);
  }

  getLang(): string {
    return this.get(LANGUAGE_KEY) || Langauge.PL;
  }
}
