<ngx-loading-bar [includeSpinner]="false" [color]="Config.LOADING_BAR_COLOR"></ngx-loading-bar>
<kg-end-session *ngIf="showEndSessionOverlay"></kg-end-session>
<kg-back-to-reservation
  *ngIf="
    isReservationStarted &&
    !isOrderPage([Routes.Reservation.Places, Routes.Payment.Form, Routes.Payment.Fail, Routes.Payment.Status])
  "
></kg-back-to-reservation>
<router-outlet></router-outlet>
<kg-cookie-banner></kg-cookie-banner>
