import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MovieCountryPipe } from '@pipes/movie-country.pipe';

@Component({
  selector: 'kg-movie-country',
  standalone: true,
  imports: [CommonModule, TranslateModule, MovieCountryPipe],
  template: `<span *ngFor="let country of countries | movieCountry; let last = last">
    <span *ngIf="country !== 'Countries.Other'"
      >{{ country | translate }}<span *ngIf="countries.length > 1 && !last">, </span></span
    >
  </span>`,
  styleUrls: ['./movie-country.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MovieCountryComponent {
  @Input() countries!: string;
}
