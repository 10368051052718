import { Pipe, PipeTransform } from '@angular/core';
import { Row, RowMap } from '@enums/row.enum';
import { ScreeningItemOutput } from '@graphql/graphql-types';
import { Ticket } from '@models/ticket.model';
import { Store } from '@ngxs/store';

@Pipe({
  name: 'ticketRowSeat',
  standalone: true,
})
export class TicketRowSeatPipe implements PipeTransform {
  constructor(private store: Store) {}

  rowMap: RowMap = Row as RowMap;

  transform(ticket: Ticket[], value: string): string {
    const store = this.store.snapshot();
    const screeningItems = store.reservation.order.screeningItems;
    const row = screeningItems.find((item: ScreeningItemOutput) => item.id === ticket[0].screeningItemId).row;
    const seat = screeningItems.find((item: ScreeningItemOutput) => item.id === ticket[0].screeningItemId).seat;
    return value === 'row' ? this.rowMap[row] : seat;
  }
}
