import { gql } from 'apollo-angular';

export const GetAvailableTicketsByOrderDocument = gql`
  query getAvailableTicketsByOrder($orderId: String!) {
    getAvailableTicketsByOrder(orderId: $orderId) {
      id
      name
      price
      tax
      seatId
      screeningItemId
      defaultPriceLevelPrice
      priceWithMandatoryExtraFees
      extraFees {
        id
        name
      }
    }
  }
`;
