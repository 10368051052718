import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { OrderEditEntryInput, OrderEntryInput } from '@graphql/graphql-types';
import { ApplyVoucherToOrderDocument } from '@graphql/mutations/reservation/apply-voucher-to-order.mutation';
import { ApplyVoucherToOrderMutation } from '@graphql/mutations/reservation/apply-voucher-to-order.mutation.generated';
import { CreateOrderDocument } from '@graphql/mutations/reservation/create-order.mutation';
import { CreateOrderMutation } from '@graphql/mutations/reservation/create-order.mutation.generated';
import { DeleteOrderDocument } from '@graphql/mutations/reservation/delete-order.mutation';
import { DeleteOrderMutation } from '@graphql/mutations/reservation/delete-order.mutation.generated';
import { EditOrderDocument } from '@graphql/mutations/reservation/edit-order.mutation';
import { EditOrderMutation } from '@graphql/mutations/reservation/edit-order.mutation.generated';
import { RemoveVoucherFromOrderDocument } from '@graphql/mutations/reservation/remove-voucher-from-order.mutation';
import { RemoveVoucherFromOrderMutation } from '@graphql/mutations/reservation/remove-voucher-from-order.mutation.generated';
import { GetAvailableTicketsByOrderDocument } from '@graphql/queries/reservation/get-available-tickets-by-order.query';
import { GetAvailableTicketsByOrderQuery } from '@graphql/queries/reservation/get-available-tickets-by-order.query.generated';
import { GetScreeningListForSelectDocument } from '@graphql/queries/reservation/get-screening-list-for-select.query';
import { GetScreeningListForSelectQuery } from '@graphql/queries/reservation/get-screening-list-for-select.query.generated';
import { GetScreenByIdDocument } from '@graphql/queries/screen/get-screen-by-id.query';
import { GetScreenByIdQuery } from '@graphql/queries/screen/get-screen-by-id.query.generated';
import { GetScreeningOccupancyByIdDocument } from '@graphql/queries/screening/get-screening-occupancy-by-id.query';
import { GetScreeningOccupancyByIdQuery } from '@graphql/queries/screening/get-screening-occupancy-by-id.query.generated';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReservationGraphqlService {
  constructor(private apollo: Apollo) {}

  getScreenById(screenId: string): Observable<FetchResult<GetScreenByIdQuery>> {
    return this.apollo.query<GetScreenByIdQuery>({
      query: GetScreenByIdDocument,
      variables: {
        screenId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getScreeningOccupancyById(screeningId: string): Observable<FetchResult<GetScreeningOccupancyByIdQuery>> {
    return this.apollo.query<GetScreeningOccupancyByIdQuery>({
      query: GetScreeningOccupancyByIdDocument,
      variables: {
        screeningId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  createOrder(orderEntries: OrderEntryInput[]): Observable<FetchResult<CreateOrderMutation>> {
    return this.apollo.mutate<CreateOrderMutation>({
      mutation: CreateOrderDocument,
      variables: {
        orderEntries,
      },
      fetchPolicy: 'no-cache',
    });
  }

  editOrder(orderId: string, orderEntries: OrderEditEntryInput[]): Observable<FetchResult<EditOrderMutation>> {
    return this.apollo.mutate<EditOrderMutation>({
      mutation: EditOrderDocument,
      variables: {
        orderId,
        orderEntries,
      },
      fetchPolicy: 'no-cache',
    });
  }

  deleteOrder(orderId: string): Observable<FetchResult<DeleteOrderMutation>> {
    return this.apollo.mutate<DeleteOrderMutation>({
      mutation: DeleteOrderDocument,
      variables: {
        orderId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getScreeningListForSelect(movieId: String): Observable<FetchResult<GetScreeningListForSelectQuery>> {
    return this.apollo.query<GetScreeningListForSelectQuery>({
      query: GetScreeningListForSelectDocument,
      variables: {
        movieId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getAvailableTicketsByOrder(orderId: String): Observable<FetchResult<GetAvailableTicketsByOrderQuery>> {
    return this.apollo.query<GetAvailableTicketsByOrderQuery>({
      query: GetAvailableTicketsByOrderDocument,
      variables: {
        orderId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  applyVoucherToOrder(orderId: string, voucherNumber: string): Observable<FetchResult<ApplyVoucherToOrderMutation>> {
    return this.apollo.mutate<ApplyVoucherToOrderMutation>({
      mutation: ApplyVoucherToOrderDocument,
      variables: {
        orderId,
        voucherNumber,
      },
      fetchPolicy: 'no-cache',
    });
  }

  removeVoucherFromOrder(
    orderId: string,
    voucherNumber: string,
  ): Observable<FetchResult<RemoveVoucherFromOrderMutation>> {
    return this.apollo.mutate<RemoveVoucherFromOrderMutation>({
      mutation: RemoveVoucherFromOrderDocument,
      variables: {
        orderId,
        voucherNumber,
      },
      fetchPolicy: 'no-cache',
    });
  }
}
