import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { COOKIE_KEY, LocalStorageService } from '@services/local-storage.service';

@Component({
  selector: 'kg-cookie-banner',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent {
  get isAccepted(): boolean {
    return this.storageService.get(COOKIE_KEY) === 'accepted';
  }

  constructor(private storageService: LocalStorageService) {}
  acceptCookies(): void {
    if (!this.isAccepted) {
      this.storageService.put(COOKIE_KEY, 'accepted');
    }
  }
}
