import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  template: '',
})
export class DestroyableComponent implements OnDestroy {
  public destroy$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$?.next();
    this.destroy$?.complete();
  }
}
