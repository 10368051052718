import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FetchResult } from '@apollo/client/core';
import { Routes } from '@configs/routes';
import { ErrorMessage } from '@enums/error-messages.enum';
import {
  OrderEditEntryInput,
  OrderEntryInput,
  OrderOutput,
  ScreeningOutput,
  TicketOutput,
} from '@graphql/graphql-types';
import { ApplyVoucherToOrderMutation } from '@graphql/mutations/reservation/apply-voucher-to-order.mutation.generated';
import { CreateOrderMutation } from '@graphql/mutations/reservation/create-order.mutation.generated';
import { DeleteOrderMutation } from '@graphql/mutations/reservation/delete-order.mutation.generated';
import { EditOrderMutation } from '@graphql/mutations/reservation/edit-order.mutation.generated';
import { RemoveVoucherFromOrderMutation } from '@graphql/mutations/reservation/remove-voucher-from-order.mutation.generated';
import { GetAvailableTicketsByOrderQuery } from '@graphql/queries/reservation/get-available-tickets-by-order.query.generated';
import { GetScreeningListForSelectQuery } from '@graphql/queries/reservation/get-screening-list-for-select.query.generated';
import { ReservationGraphqlService } from '@graphql/services/reservation-graphql.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { ResetReservation } from '../_store/actions/reset-reservation.action';
import { UpdateMovieTitle } from '../_store/actions/update-movie-title.action';
import { UpdatePaymentId } from '../_store/actions/update-payment-id.action';
import { UpdateScreenRoomNumber } from '../_store/actions/update-screen-room-number.action';
import { UpdateScreeningTime } from '../_store/actions/update-screening-time.action';
import { UpdateTickets } from '../_store/actions/update-tickets.action';
import { UpdateVoucher } from '../_store/actions/update-vouchers.action';
import { ReservationTimerService } from './reservation-timer.service';

@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();

  private readonly _modalCancelled$: Subject<string> = new Subject();
  readonly modalCancelled$: Observable<string> = this._modalCancelled$.asObservable();

  private readonly _modalAccepted$: Subject<boolean> = new Subject();
  readonly modalAccepted$: Observable<boolean> = this._modalAccepted$.asObservable();

  defaultConfirmTitle = this.t.instant('Reservation.ConfirmCancelTitle');

  constructor(
    private modal: NzModalService,
    private t: TranslateService,
    private store: Store,
    private router: Router,
    private reservationGraphqlService: ReservationGraphqlService,
    private reservationTimerService: ReservationTimerService,
  ) {}

  public setLoader(value: boolean): void {
    this._loading$.next(value);
  }

  public showCancelReservationConfirm(screeningId: string, redirect = true, title = this.defaultConfirmTitle): void {
    this.modal.confirm({
      nzTitle: title,
      nzContent: this.t.instant('Reservation.ConfirmCancelDescription'),
      nzOkText: this.t.instant('Reservation.ConfirmCancelYesText'),
      nzCancelText: this.t.instant('Reservation.ConfirmCancelNoText'),
      nzOkType: 'primary',
      nzCentered: true,
      nzOkDanger: true,
      nzOnOk: () => this.cancelReservation(screeningId, redirect),
      nzOnCancel: () => this._modalCancelled$.next(screeningId),
    });
  }

  private cancelReservation(screeingId: string, redirect = true): void {
    const store = this.store.snapshot();
    this.deleteOrder(store.reservation.order.orderId).subscribe({
      next: () => {
        this.store.dispatch(new ResetReservation());
        this.reservationTimerService.stopCountdown();
        this._modalAccepted$.next(true);
      },
      error: (error) => {
        if (error.message === ErrorMessage.BASKET_IS_CLOSED) {
          this.store.dispatch(new ResetReservation());
          this.reservationTimerService.stopCountdown();
          this._modalAccepted$.next(true);
        }
      },
      complete: () => {
        if (redirect) {
          this.router.navigate([`${Routes.Reservation.Places}/${screeingId}`]);
        }
      },
    });
  }

  resetStore(): void {
    this.store.dispatch(new UpdateVoucher(null));
    this.store.dispatch(new ResetReservation());
    this.store.dispatch(new UpdateTickets([]));
    this.store.dispatch(new UpdateScreeningTime(null));
    this.store.dispatch(new UpdateMovieTitle(null, null));
    this.store.dispatch(new UpdateScreenRoomNumber(null));
    this.store.dispatch(new UpdatePaymentId(null));
  }

  createOrder(orderEntries: OrderEntryInput[]): Observable<OrderOutput> {
    return this.reservationGraphqlService
      .createOrder(orderEntries)
      .pipe(map((res: FetchResult<CreateOrderMutation>) => res.data?.createOrder as OrderOutput));
  }

  editOrder(orderId: string, orderEntries: OrderEditEntryInput[]): Observable<OrderOutput> {
    return this.reservationGraphqlService
      .editOrder(orderId, orderEntries)
      .pipe(map((res: FetchResult<EditOrderMutation>) => res.data?.editOrder as OrderOutput));
  }

  deleteOrder(orderId: string): Observable<boolean> {
    return this.reservationGraphqlService
      .deleteOrder(orderId)
      .pipe(map((res: FetchResult<DeleteOrderMutation>) => res.data?.deleteOrder as boolean));
  }

  fetchScreeningListForSelect(movieId: String): Observable<ScreeningOutput[]> {
    return this.reservationGraphqlService
      .getScreeningListForSelect(movieId)
      .pipe(
        map(
          (res: FetchResult<GetScreeningListForSelectQuery>) =>
            res.data?.getScreeningListByMovieId as ScreeningOutput[],
        ),
      );
  }

  fetchAvailableTicketsByOrder(orderId: String): Observable<TicketOutput[]> {
    return this.reservationGraphqlService
      .getAvailableTicketsByOrder(orderId)
      .pipe(
        map(
          (res: FetchResult<GetAvailableTicketsByOrderQuery>) => res.data?.getAvailableTicketsByOrder as TicketOutput[],
        ),
      );
  }

  applyVoucherToOrder(orderId: string, voucherNumber: string): Observable<OrderOutput> {
    return this.reservationGraphqlService
      .applyVoucherToOrder(orderId, voucherNumber)
      .pipe(map((res: FetchResult<ApplyVoucherToOrderMutation>) => res.data?.applyVoucherToOrder as OrderOutput));
  }

  removeVoucherFromOrder(orderId: string, voucherNumber: string): Observable<OrderOutput> {
    return this.reservationGraphqlService
      .removeVoucherFromOrder(orderId, voucherNumber)
      .pipe(map((res: FetchResult<RemoveVoucherFromOrderMutation>) => res.data?.removeVoucherFromOrder as OrderOutput));
  }
}
