import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ScreeningDatePipe } from '@pipes/screening-date.pipe';

export interface ScreeningSelectOption {
  value: string;
  label: string;
}

@Component({
  selector: 'kg-screening-select',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, NgSelectModule, ScreeningDatePipe],
  template: `
    <div class="select">
      <ng-select
        [(ngModel)]="selectedOption"
        (ngModelChange)="onSelectChange($event)"
        [items]="options"
        bindLabel="label"
        bindValue="value"
        [searchable]="false"
        [clearable]="false"
        [disabled]="disabled"
        dropdownPosition="bottom"
      >
        <ng-template ng-option-tmp let-item="item">
          {{ item.label | screeningDatePipe }}
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
          <div class="select-placeholder">{{ 'Reservation.SelectedScreening' | translate }}</div>
          <div class="select-value">{{ item.label | screeningDatePipe }}</div>
        </ng-template>
      </ng-select>
    </div>
  `,
  styleUrls: ['./screening-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreeningSelectComponent {
  @Input() options!: ScreeningSelectOption[];
  @Input() selectedOption!: string;
  @Input() disabled = false;

  @Output() onChange: EventEmitter<string> = new EventEmitter();

  constructor(private cd: ChangeDetectorRef) {}

  onSelectChange(value: string): void {
    this.onChange.emit(value);
  }

  selectOption(value: string): void {
    this.selectedOption = value;
    this.cd.detectChanges();
  }
}
