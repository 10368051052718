import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthorizedUserGuard } from '@guards/authorized-user.guard';
import { UnauthorizedUserGuard } from '@guards/unauthorized-user.guard';
import { TranslateModule } from '@ngx-translate/core';
import { LuxonDatePipe } from '@pipes/luxon-date.pipe';
import { ReservationTimerPipe } from '@pipes/reservation-timer.pipe';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ToastrModule } from 'ngx-toastr';
import { CookieBannerComponent } from './_components/cookie-banner/cookie-banner.component';
import { NotificationComponent } from './_components/notification/notification.component';

const SHARED_GUARDS = [AuthorizedUserGuard, UnauthorizedUserGuard];
const SHARED_PROVIDERS = [ReservationTimerPipe, DatePipe, LuxonDatePipe, NzModalService];
const SHARED_MODULES = [
  CommonModule,
  TranslateModule,
  AngularSvgIconModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  ToastrModule.forRoot({
    toastComponent: NotificationComponent,
    positionClass: 'toast-top-center',
  }),
  CookieBannerComponent,
];

@NgModule({
  imports: [SHARED_MODULES],
  exports: [SHARED_MODULES],
  providers: [SHARED_GUARDS, SHARED_PROVIDERS],
})
export class SharedModule {}
