import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Config } from '@configs/config';
import { Routes } from '@configs/routes';
import Utils from '@helpers/utils';
import { TranslateService } from '@ngx-translate/core';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { ActionCompletion, Actions, ofActionCompleted } from '@ngxs/store';
import { ReservationTimerService } from '@services/reservation-timer.service';
import { Settings } from 'luxon';
import { filter, takeUntil } from 'rxjs';
import { DestroyableComponent } from './_components/destroyable.component';
import { UpdateSession } from './_store/actions/update-session.action';
import { ReservationState } from './_store/reservation.state';
import { TechnicalStatusGraphqlService } from '@graphql/services/technical-status-graphql.service';
import { environment } from '@env';

@Component({
  selector: 'kg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends DestroyableComponent implements OnInit, AfterViewInit {
  @SelectSnapshot(ReservationState.isReservationStarted) isReservationStarted!: boolean;
  @SelectSnapshot(ReservationState.getDateTimeToLive) dateTimeToLive!: string;

  Config = Config;
  Routes = Routes;
  showEndSessionOverlay = false;

  @HostListener('document:visibilitychange', ['$event'])
  onVisibilityChange() {
    if (!this.isReservationStarted) {
      return;
    }
    if (!document.hidden) {
      this.reservationTimerService.startCountdown(Utils.getReservationTimeDiff(this.dateTimeToLive));
    }
  }

  constructor(
    private actions$: Actions,
    private router: Router,
    private reservationTimerService: ReservationTimerService,
    private technicalService: TechnicalStatusGraphqlService,
    private swUpdate: SwUpdate,
    private t: TranslateService,
    private activated: ActivatedRoute,
  ) {
    super();
    Settings.defaultLocale = Config.LOCALE;
  }

  ngOnInit(): void {
    this.actions$
      .pipe(ofActionCompleted(UpdateSession), takeUntil(this.destroy$))
      .subscribe((completion: ActionCompletion) => {
        this.showEndSessionOverlay = completion.action.closed;
      });
  }

  ngAfterViewInit(): void {
    this.watchForAppUdate();
    environment.enableTechnicalBreakCheck ? this.startTechnicalInterval() : null;
  }

  startTechnicalInterval() {
    const checkTechnical = () => {
      const currentPath = this.activated.snapshot.firstChild?.routeConfig?.path;
      if (currentPath && Routes.TechnicalBreak.indexOf(currentPath) > -1) return;
      this.technicalService.getTechnicalBreakStatus().subscribe({
        next: (res) => {
          if (res.getTechnicalBreakStatus) {
            this.router.navigate([Routes.TechnicalBreak]);
          }
        },
      });
    };

    this.router.events.pipe(filter((i) => i instanceof NavigationEnd)).subscribe((e) => {
      checkTechnical();
    });

    setInterval(() => checkTechnical(), 30000); //30s
  }

  watchForAppUdate(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm(this.t.instant('General.AppUpdate'))) {
          window.location.reload();
        }
      });
    }
  }

  public isOrderPage(urls: string[]): boolean {
    for (const u of urls) {
      if (this.router.url.includes(u)) {
        return true;
      }
    }
    return false;
  }
}
