import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AlertType } from '@enums/alert-type.enum';
import { ErrorMessage } from '@enums/error-messages.enum';
import { SeatGrid } from '@interfaces/seat-grid.interface';
import { Screen } from '@models/screen.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Store } from '@ngxs/store';
import { SeatIconPipe } from '@pipes/resolve-seat-icon.pipe';
import { NotificationService } from '@services/notification.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { Observable } from 'rxjs';
import { ReservationState } from 'src/app/_store/reservation.state';
import { SeatGridService } from '../../_services/seat-grid.service';
import { AlertComponent } from '../alert/alert.component';
import { DestroyableComponent } from '../destroyable.component';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'kg-seat-grid',
  standalone: true,
  imports: [
    CommonModule,
    SeatIconPipe,
    AngularSvgIconModule,
    LoaderComponent,
    NzAlertModule,
    TranslateModule,
    AlertComponent,
  ],
  templateUrl: './seat-grid.component.html',
  styleUrls: ['./seat-grid.component.scss'],
})
export class SeatGridComponent extends DestroyableComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewSelectSnapshot(ReservationState.getPaymentId) paymentId!: string;
  @ViewChild('parent') parent!: ElementRef;
  @Input() screenId!: string;
  @Input() screeningId!: string;
  @Input() screen!: Screen;
  @Input() buyTimeExceeded: boolean = false;

  ErrorMessage = ErrorMessage;
  AlertType = AlertType;

  @Output() selectedSeat = new EventEmitter<SeatGrid>();

  get loading$(): Observable<boolean> {
    return this.seatGridService.loading$;
  }

  get seatGrid$(): Observable<SeatGrid[][]> {
    return this.seatGridService.seatGrid$;
  }

  get scrollWidth(): string {
    return `${this.parent?.nativeElement.scrollWidth}px`;
  }

  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.scrollWidth;
  }

  constructor(
    private readonly seatGridService: SeatGridService,
    private store: Store,
    private n: NotificationService,
    private t: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getData(this.screen, this.screeningId);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1200);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['screen'] &&
      changes['screen'].currentValue &&
      changes['screeningId'] &&
      changes['screeningId'].currentValue
    ) {
      this.getData(changes['screen'].currentValue, changes['screeningId'].currentValue);
    }
  }

  getData(screen: Screen, screeningId: string): void {
    this.seatGridService.getData(screen, screeningId);
  }

  onSeatSelected(seat: SeatGrid): void {
    if ((seat.seatWrapper.isOccupied && !seat.seatWrapper.isSelected) || seat.seatWrapper.isBlocked) {
      this.n.onWarning(this.t.instant('Reservation.ThisSeatIsTaken'));
      return;
    }

    const store = this.store.snapshot();
    const items = store.reservation.order?.screeningItems || [];

    if (items.length === 9 && !seat.seatWrapper.isSelected && seat.seatWrapper.seats.length === 2) {
      this.n.onWarning(this.t.instant('Reservation.YouCanSelectOneMoreSeat'));
      return;
    }

    if (items.length >= 10 && !seat.seatWrapper.isSelected) {
      this.n.onWarning(this.t.instant('Reservation.SeatLimitReached'));
      return;
    }

    seat.seatWrapper.isLoading = true;
    this.seatGridService.setLoader(true);
    this.selectedSeat.emit(seat);
  }
}
