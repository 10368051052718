import { Pipe, PipeTransform } from '@angular/core';
import { Config } from '@configs/config';
import { LocalStorageService } from '@services/local-storage.service';
import { DateTime } from 'luxon';

@Pipe({
  name: 'screeningDatePipe',
  standalone: true,
  pure: false,
})
export class ScreeningDatePipe implements PipeTransform {
  constructor(private storage: LocalStorageService) {}
  transform(date: string): string {
    let formattedLabel = DateTime.fromISO(date).toFormat(Config.MOVIE_SCREENING_SELECT_DATETIME_FORMAT, {
      locale: this.storage.getLang(),
    });
    formattedLabel = `${formattedLabel.charAt(0).toUpperCase() + formattedLabel.slice(1)}`;
    return date ? formattedLabel.replace(/\./g, '') : '';
  }
}
