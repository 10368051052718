import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'movieCountry',
  standalone: true,
  pure: false,
})
export class MovieCountryPipe implements PipeTransform {
  transform(countries: string | undefined): string[] {
    let translated = [];
    if (countries) {
      for (const country of countries.split(',')) {
        translated.push(`Countries.${country.trim()}`);
      }
    }
    return translated;
  }
}
