import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TapeType } from '@enums/tape-type.enum';

@Component({
  selector: 'kg-tape',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="tape" [ngClass]="'tape-' + type">
      <img [src]="'assets/img/' + img + '.svg'" />
    </div>
  `,
  styleUrls: ['./tape.component.scss'],
})
export class TapeComponent {
  @Input() type?: TapeType;
  @Input() img?: string = 'tape';
}
