import { ScreenElementOutput } from '@graphql/graphql-types';

export class ScreenElement implements ScreenElementOutput {
  id?: string;
  name?: string;
  seatIds?: string[];

  constructor(data: ScreenElement | ScreenElementOutput) {
    data ? Object.assign(this, data) : '';
  }
}
