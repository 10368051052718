import { gql } from 'apollo-angular';

export const GroupSecondFragment = gql`
  fragment GroupSecond on GroupSecondOutput {
    color
    description
    displayMode
    ico
    id
    name
    salesMode
    seatIds
    type
  }
`;
