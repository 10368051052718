import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

const PREFIX: string = './assets/i18n/';
const SUFFIX: string = '.json';
const LANG_FILES = [{ prefix: `${PREFIX}`, suffix: `${SUFFIX}` }];

export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, LANG_FILES);
}

export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient, public resources: { prefix: string; suffix: string }[]) {}

  public getTranslation(lang: string): any {
    return forkJoin(
      this.resources.map((config) => {
        return this.http.get(`${config.prefix}${lang}${config.suffix}`);
      }),
    ).pipe(
      map((response) => {
        return response.reduce((a, b) => {
          return Object.assign(a, b);
        });
      }),
    );
  }
}
