import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@services/language.service';

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      const langService: LanguageService = injector.get(LanguageService);
      locationInitialized.then(() => {
        const lang = langService.getInitLanguage();
        langService.useLanguage(lang).add(() => {
          resolve();
        });
      });
    });
}
