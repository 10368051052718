import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Config } from '@configs/config';
import { SoundType } from '@enums/sound-type.enum';
import Utils from '@helpers/utils';
import { Screening } from '@models/screening.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MovieTitlePipe } from '@pipes/movie-title.pipe';
import { ScreeningDatePipe } from '@pipes/screening-date.pipe';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AutoTrailerComponent } from '../auto-trailer/auto-trailer.component';
import { CardWithIconComponent } from '../card-with-icon/card-with-icon.component';
import { MovieCountryComponent } from '../movie-country/movie-country.component';
import { ReservationTimerComponent } from '../reservation-timer/reservation-timer.component';
import { ScreeningLanguageComponent } from '../screening-language/screening-language.component';

@Component({
  selector: 'kg-reservation-info-card',
  standalone: true,
  imports: [
    CommonModule,
    ScreeningLanguageComponent,
    ReservationTimerComponent,
    AutoTrailerComponent,
    CardWithIconComponent,
    AngularSvgIconModule,
    TranslateModule,
    ScreeningDatePipe,
    MovieCountryComponent,
    MovieTitlePipe,
  ],
  templateUrl: './reservation-info-card.component.html',
  styleUrls: ['./reservation-info-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationInfoCardComponent {
  @Input() screening!: Screening;
  @Input() hideVideoMobile: boolean = false;
  @Input() showParkingInfo: boolean = false;
  @Input() showPathInfo: boolean = false;
  @Input() showScreeningTimeFrom: boolean = true;
  @Input() showMobile: boolean = false;
  @Input() showDetails: boolean = true;
  @Input() hideTrailerMobile: boolean = false;
  @Input() showTimerMobile: boolean = true;
  Config = Config;
  SoundType = SoundType;
  Utils = Utils;
  constructor(public t: TranslateService) {}
}
