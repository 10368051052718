import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { ApolloClientOptions, ApolloLink, InMemoryCache, split } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { environment } from '@env';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { graphqlErrorMiddleware } from './middlewares/error.middleware';
import { graphqlRequestHeadersMiddleware } from './middlewares/request-headers.middleware';

const uri = environment.graphqlApi;
const websocketUri = environment.websocketUri;

const getLink = (httpLink: HttpLink) => {
  const http = httpLink.create({
    uri: uri,
    // extractFiles,
  });

  const ws = new WebSocketLink({
    uri: websocketUri,
    options: {
      reconnect: true,
    },
  });

  return split(
    ({ query }) => {
      const def = getMainDefinition(query);
      return def?.kind === 'OperationDefinition' && def?.operation === 'subscription';
    },
    ws,
    http,
  );
};

export function createApollo(
  httpLink: HttpLink,
  router: Router,
  notificationService: NotificationService,
  translateService: TranslateService,
): ApolloClientOptions<any> {
  return {
    link: ApolloLink.from([
      graphqlRequestHeadersMiddleware(),
      graphqlErrorMiddleware(router, notificationService, translateService),
      getLink(httpLink),
    ]),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, Router, NotificationService, TranslateService],
    },
  ],
})
export class GraphQLModule {}
