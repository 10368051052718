export class Config {
  static readonly DATE_FORMAT_BACKEND = "yyyy-MM-dd'T'HH:mm:ss'Z'";
  static readonly DATE_FORMAT_REPERTOIRE = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  };
  static readonly LOCALE = 'pl';
  static readonly DATE_FORMAT_URL = 'yyyy-MM-dd';
  static readonly DATE_FORMAT_CALENDAR = 'yyyy-MM-dd';
  static readonly SCREENING_TIME_FORMAT = 'H:mm';
  static readonly MOVIE_SCREENING_TIME_FORMAT = 'HH:mm';
  static readonly YT_EMBED_LINK = 'https://www.youtube.com/embed';
  static readonly MOVIE_SCREENING_SELECT_DATETIME_FORMAT = 'EEE, d MMMM yyyy, HH:mm';
  static readonly MOVIE_DURATION_FORMAT = "h'h' mm'm'";
  static readonly LOADING_BAR_COLOR = '#b0a29c';
  static readonly PLACE_MAPS_ADDRESS = 'https://goo.gl/maps/hTdkuaTf9YqfdAnN6';
  static readonly PLACE_MAPS_LOCATION = 'Fabryka Norblina, Żelazna 51/53, 00-841 Warszawa, Polska';
  static readonly DEFAULT_CURRENCY_FORMAT = {
    CurrencyCode: 'PLN',
    Display: 'code',
    DigitsInfo: '1.0',
  };
  static readonly DEFAULT_CURRENCY_LOCALE = 'pl';
}
