import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'kg-loader',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  template: `<div
    class="loader"
    [class.center]="center"
    [class.tickets-loader]="ticketsLoader"
    [class.seat-loader]="seatLoader"
    [class.payment-loader]="paymentLoader"
  >
    <svg-icon src="assets/img/loader_old.svg"></svg-icon>
  </div> `,
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input() center: boolean = false;
  @Input() seatLoader: boolean = false;
  @Input() ticketsLoader: boolean = false;
  @Input() paymentLoader: boolean = false;
}
