<div class="cookie-banner" *ngIf="!isAccepted">
  <div class="cookie-banner-title">{{ 'General.CookieText' | translate }}</div>
  <div class="cookie-banner-actions">
    <button class="btn btn-primary btn-cookie" (click)="acceptCookies()">
      {{ 'General.AcceptCookie' | translate }}
    </button>
    <a class="btn btn-primary btn-cookie" href="https://kinogram.pl/polityka-prywatnosci/" target="_blank">{{
      'General.PrivacyPolicy' | translate
    }}</a>
  </div>
</div>
