import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ReservationTimerPipe } from '@pipes/reservation-timer.pipe';
import { Subject, Subscription, map, takeUntil, takeWhile, timer } from 'rxjs';
import { UpdateSession } from '../_store/actions/update-session.action';
import { UpdateTimer } from '../_store/actions/update-timer.action';

const ONE_SECOND = 1000;

@Injectable({
  providedIn: 'root',
})
export class ReservationTimerService {
  private timer$: Subscription = new Subscription();
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private store: Store, private reservationTimerPipe: ReservationTimerPipe) {}

  startCountdown(reservationLiveTime: number): void {
    this.stopCountdown();

    this.timer$ = timer(0, ONE_SECOND)
      .pipe(
        map((tick: number) => reservationLiveTime - tick * ONE_SECOND - 1),
        takeWhile((remainingTime: number) => remainingTime >= 0),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (remainingTime: number) => {
          const time = this.reservationTimerPipe.transform(remainingTime);
          this.store.dispatch(new UpdateTimer(time));
        },
        complete: () => {
          this.store.dispatch(new UpdateSession(true));
          this.stopCountdown();
        },
      });
  }

  public stopCountdown(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.timer$?.unsubscribe();
  }
}
