import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { ScreenOutput } from '@graphql/graphql-types';
import { GetScreenByIdQuery } from '@graphql/queries/screen/get-screen-by-id.query.generated';
import { GetScreeningOccupancyByIdQuery } from '@graphql/queries/screening/get-screening-occupancy-by-id.query.generated';
import { ReservationGraphqlService } from '@graphql/services/reservation-graphql.service';
import { SeatGrid } from '@interfaces/seat-grid.interface';
import { SeatWrapper } from '@interfaces/seat-wrapper.interface';
import { ScreenBlockedSeat } from '@models/screen-blocked-seat.model';
import { ScreenSeat } from '@models/screen-seat.model';
import { Screen } from '@models/screen.model';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class SeatGridService {
  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();

  private readonly _seatGrid$: BehaviorSubject<SeatGrid[][]> = new BehaviorSubject<SeatGrid[][]>([[]]);
  readonly seatGrid$: Observable<SeatGrid[][]> = this._seatGrid$.asObservable();

  constructor(
    private readonly reservationGraphqlService: ReservationGraphqlService,
    private store: Store,
    private n: NotificationService,
  ) {}

  getData(screen: Screen, screeningId: string): void {
    this.getScreeningOccupancyById(screeningId).subscribe({
      next: (occupiedSeats: string[]) => {
        if (occupiedSeats) {
          this.generateSeatRows(new Screen(screen), occupiedSeats);
        }
      },
      error: (error) => {
        this.setLoader(false);
      },
      complete: () => this.setLoader(false),
    });
  }

  public setLoader(value: boolean): void {
    this._loading$.next(value);
  }

  private generateSeatRows(screen: Screen, occupiedSeats: string[]): void {
    if (!screen.rows || !screen.cols || !screen.seats) {
      return;
    }
    const store = this.store.snapshot();
    const items = store.reservation.order?.screeningItems;
    const collection: SeatGrid[][] = [];
    for (const row of screen.rows) {
      const rows: SeatGrid[] = [];
      for (const col of screen.cols) {
        const rowIndex = screen.rows.indexOf(row);
        const colIndex = screen.cols.indexOf(col);
        const seatWrapper = this.createSeatWrapper(screen, rowIndex, colIndex, occupiedSeats, items);
        const obj: SeatGrid = {
          row,
          col,
          seatWrapper,
        };
        obj.isHidden = !!(
          rows.length &&
          obj.seatWrapper.group &&
          rows[rows.length - 1].seatWrapper.group?.id === obj.seatWrapper.group.id
        );
        rows.push(obj);
      }
      collection.push(rows);
    }
    this._seatGrid$.next(collection);
  }

  private createSeatWrapper(
    screen: Screen,
    rowIndex: number,
    colIndex: number,
    occupiedSeats: string[],
    screeningItems: [],
  ): SeatWrapper {
    const { seats, groups } = screen;
    const seat = seats!.find((s) => s.colId === screen.cols![colIndex].id && s.rowId === screen.rows![rowIndex].id)!;
    const group = seat.groupId ? groups?.find((g) => g.id === seat.groupId) : undefined;
    const filteredSeats = seats?.filter((s) => (group ? group.seatIds?.includes(s.id!) : s.id === seat.id)) ?? [];
    const isBlocked = this.isBlocked(filteredSeats, screen.blockedList);
    const isOccupied = this.isOccupied(filteredSeats, occupiedSeats);
    const isSelected = this.isSelected(filteredSeats, screeningItems);

    return {
      isBlocked,
      isOccupied,
      seats: filteredSeats,
      group,
      isSelected,
      isLoading: false,
    };
  }

  private isSelected(seats: ScreenSeat[], screeningItems: any[]): boolean {
    if (!screeningItems) return false;
    for (let index = 0; index < seats.length; index++) {
      if (screeningItems.filter((s) => s.seatId === seats[index].id).length) return true;
    }
    return false;
  }

  private isOccupied(seats: ScreenSeat[], occupiedSeats: string[]): boolean {
    for (let index = 0; index < seats.length; index++) {
      if (occupiedSeats.includes(seats[index].id!)) return true;
    }
    return false;
  }

  private isBlocked(seats: ScreenSeat[], blockedList: ScreenBlockedSeat[] | undefined): boolean {
    if (!blockedList || !seats) return false;
    for (let index = 0; index < seats.length; index++) {
      if (blockedList.filter((b) => b.seatId === seats[index].id).length) return true;
    }
    return false;
  }

  private getScreenById(screenId: string): Observable<ScreenOutput> {
    return this.reservationGraphqlService
      .getScreenById(screenId)
      .pipe(map((res: FetchResult<GetScreenByIdQuery>) => res.data?.getScreenById as ScreenOutput));
  }

  private getScreeningOccupancyById(screeningId: string): Observable<string[]> {
    return this.reservationGraphqlService
      .getScreeningOccupancyById(screeningId)
      .pipe(
        map(
          (res: FetchResult<GetScreeningOccupancyByIdQuery>) =>
            res.data?.getScreeningOccupancyById.occupiedSeats as string[],
        ),
      );
  }
}
