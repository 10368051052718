import { ApplyVoucherFragment } from '@graphql/fragments/reservation/apply-voucher.fragment';
import { gql } from 'apollo-angular';

export const ApplyVoucherToOrderDocument = gql`
  mutation applyVoucherToOrder($orderId: String!, $voucherNumber: String!) {
    applyVoucherToOrder(orderId: $orderId, voucherNumber: $voucherNumber) {
      ...ApplyVoucher
    }
  }
  ${ApplyVoucherFragment}
`;
