import { PathLocationStrategy, PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { UrlSerializer } from '@angular/router';
import { VOUCHER_PARAM_NAME } from '@pages/reservation/places/places.component';

@Injectable()
export class PreserveVoucherQueryLocationStrategy extends PathLocationStrategy {
  private get search(): string {
    return this.platformLocation?.search ?? '';
  }
  constructor(private platformLocation: PlatformLocation, private urlSerializer: UrlSerializer) {
    super(platformLocation);
  }

  override prepareExternalUrl(internal: string): string {
    const path = super.prepareExternalUrl(internal);
    const existingURLSearchParams = new URLSearchParams(this.search);
    const params: any = {};

    if (existingURLSearchParams.get(VOUCHER_PARAM_NAME)) {
      params[VOUCHER_PARAM_NAME] = existingURLSearchParams.get(VOUCHER_PARAM_NAME);
    }

    const urlTree = this.urlSerializer.parse(path);
    const nextQueryParams = urlTree.queryParams;

    urlTree.queryParams = { ...params, ...nextQueryParams };

    return urlTree.toString();
  }
}
