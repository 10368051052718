import { HttpHeaders } from '@angular/common/http';
import { inject } from '@angular/core';
import { ApolloLink } from '@apollo/client/core';
import { LocalStorageService } from '@services/local-storage.service';

export const graphqlRequestHeadersMiddleware = () => {
  const storage = inject(LocalStorageService);

  return new ApolloLink((operation, forward) => {
    let httpHeaders = new HttpHeaders();
    const lang = storage.getLang();

    httpHeaders = httpHeaders.set('accept-language', lang);
    operation.setContext({ headers: httpHeaders });

    return forward(operation);
  });
};
