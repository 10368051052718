import { BlockedSeatOutput } from '@graphql/graphql-types';

export class ScreenBlockedSeat implements BlockedSeatOutput {
  id?: string;
  seatId?: string;

  constructor(data: ScreenBlockedSeat | BlockedSeatOutput) {
    data ? Object.assign(this, data) : '';
  }
}
