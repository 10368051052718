import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from '@services/user.service';
import { AuthStorageService } from '@services/auth-storage.service';

@Injectable()
export class AuthorizedUserGuard implements CanActivate {
  constructor(private readonly u: UserService, private readonly a: AuthStorageService) {}

  canActivate(): boolean {
    return !!(this.u.user && this.a.jwt);
  }
}
