import { gql } from 'apollo-angular';
import { BlockedSeatFragment } from './screen-blocked-seat.fragment';
import { ColumnFragment } from './screen-column.fragment';
import { ScreenElementFragment } from './screen-element.fragment';
import { GroupSecondFragment } from './screen-group-second.fragment';
import { RowFragment } from './screen-row.fragment';
import { SeatFragment } from './screen-seat.fragment';

export const ScreenFragment = gql`
  fragment Screen on ScreenOutput {
    defaultSeatDescription
    defaultSeatGroupName
    id
    name
    number
    feature
    ffaNumber
    proCinema
    screenElements {
      ...ScreenElement
    }
    screenGroupId
    type
    seats {
      ...Seat
    }
    rows {
      ...Row
    }
    cols {
      ...Column
    }
    groups {
      ...GroupSecond
    }
    blockedList {
      ...BlockedSeat
    }
  }
  ${SeatFragment}
  ${RowFragment}
  ${GroupSecondFragment}
  ${ColumnFragment}
  ${BlockedSeatFragment}
  ${ScreenElementFragment}
`;
