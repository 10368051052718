import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  appName: 'KinoGram',
  unauthenticatedBaseUrl: '/',
  authenticatedBaseUrl: '/',
  localStoragePrefix: 'kg-',
  graphqlApi: 'https://dev-kinogram.develtio.dev/api/graphql',
  websocketUri: 'wss://dev-kinogram.develtio.dev/api/graphql',
  staticAssetsUrl: 'https://dev-kinogram.develtio.dev/api/static',
  enableTechnicalBreakCheck: true,
};
