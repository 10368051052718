import { CommonModule, CurrencyPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AlertType } from '@enums/alert-type.enum';
import { FormControls } from '@enums/form-controls.enum';
import { InputType } from '@enums/input-type.enum';
import { ScreeningItemOutput } from '@graphql/graphql-types';
import { Ticket } from '@models/ticket.model';
import { TranslateModule } from '@ngx-translate/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Actions, ofActionCompleted } from '@ngxs/store';
import { PluralizePipe } from '@pipes/pluralize.pipe';
import { TicketRowSeatPipe } from '@pipes/ticket-row-seat.pipe';
import { TicketsAmountPipe } from '@pipes/tickets-amount.pipe';
import { ReservationService } from '@services/reservation.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UpdateTickets } from 'src/app/_store/actions/update-tickets.action';
import { AppliedVoucher } from 'src/app/_store/models/reservation.model';
import { ReservationState } from 'src/app/_store/reservation.state';
import { AlertComponent } from '../alert/alert.component';
import { FloatLabelComponent } from '../float-label/float-label.component';
import { LoaderComponent } from '../loader/loader.component';
import { TicketComponent } from '../ticket/ticket.component';
import { VoucherComponent } from '../voucher/voucher.component';

@Component({
  selector: 'kg-tickets',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TicketComponent,
    TicketRowSeatPipe,
    TicketsAmountPipe,
    LoaderComponent,
    PluralizePipe,
    FloatLabelComponent,
    NzFormModule,
    ReactiveFormsModule,
    NzInputModule,
    AngularSvgIconModule,
    LoaderComponent,
    VoucherComponent,
    AlertComponent,
  ],
  providers: [CurrencyPipe],
  template: `
    <div class="tickets" [class.is-visible]="ticketGroup.length">
      <div class="tickets-header">
        <div class="tickets-header-left">
          <h2 class="tickets-header-title">
            <span>{{ 'Tickets.Your' | translate }}</span>
            {{ 'Tickets.TicketsLower' | translate }}
            <kg-loader *ngIf="loading$ | async" [ticketsLoader]="true"></kg-loader>
          </h2>
          <button
            class="btn btn-link"
            [disabled]="!ticketGroup.length"
            *ngIf="!showVoucherInput"
            (click)="showVoucherInput = true"
          >
            {{ 'Tickets.HaveAVoucher' | translate }}
          </button>
          <button
            class="btn btn-link"
            [disabled]="!ticketGroup.length"
            *ngIf="showVoucherInput && !vouchers"
            (click)="showVoucherInput = false"
          >
            {{ 'Tickets.NoVoucher' | translate }}
          </button>
          <button
            class="btn btn-link"
            [disabled]="!ticketGroup.length"
            *ngIf="showVoucherInput && vouchers && vouchers.length > 0"
            (click)="removeVoucher()"
          >
            {{ 'Tickets.RemoveVoucher' | translate }}
          </button>
        </div>
        <div class="tickets-header-right">
          <div class="tickets-header-amount-title">{{ 'Tickets.Amount' | translate }}</div>
          <div class="tickets-header-amount-summary">
            {{ ticketGroup.length }} {{ ticketGroup.length | pluralize | translate }},
            {{ screeningItems | ticketsAmount }}
          </div>
        </div>
      </div>
      <div
        class="vouchers-form"
        [class.voucher-applied]="vouchers && vouchers.length > 0"
        [hidden]="!showVoucherInput || !ticketGroup.length"
      >
        <div class="vouchers-form-title">{{ 'Tickets.Your voucher' | translate }}</div>
        <form nz-form [formGroup]="form" (keydown.enter)="applyVoucher()">
          <nz-form-item>
            <nz-form-control>
              <kg-float-label label="{{ 'Reservation.Write voucher' | translate }}">
                <input
                  class="is-uppercase has-icon voucher-number-input"
                  [formControlName]="FormControls.VoucherNumber"
                  placeholder=" "
                  nz-input
                  [type]="InputType.TEXT"
                  [attr.disabled]="(vouchers && vouchers.length > 0) || paymentId"
                />
              </kg-float-label>
            </nz-form-control>
          </nz-form-item>
        </form>
        <kg-alert
          *ngIf="isVoucherFromUrl && !((vouchers && vouchers.length > 0) || paymentId)"
          class="voucher-from-url-alert"
          [message]="'Tickets.VoucherFromUrlInfo' | translate"
          [type]="AlertType.Primary"
        ></kg-alert>
        <button
          class="btn btn-success"
          [disabled]="!hasVoucherNumber || paymentId || (loading$ | async)"
          (click)="applyVoucher()"
          *ngIf="!vouchers"
        >
          <svg-icon *ngIf="!(loading$ | async)" src="assets/img/icons/check-dark.svg"></svg-icon>
          <kg-loader *ngIf="loading$ | async" [center]="true" [seatLoader]="true"></kg-loader>
          {{ 'Tickets.ApplyVoucher' | translate }}
        </button>
      </div>
      <div
        class="tickets-list"
        [class.voucher-form-visible]="showVoucherInput"
        *ngIf="ticketGroup.length; else noTicketsTemplate"
      >
        <kg-ticket
          *ngFor="let group of ticketGroup; trackBy: trackByTicketIndex"
          [ticketGroup]="group"
          [disabled$]="loading$"
          (onRemoveTicketWithVoucher)="resetVoucherNumberControl()"
          (onSelect)="onSelect.emit($event)"
          [row]="group | ticketRowSeat : 'row'"
          [seat]="group | ticketRowSeat : 'seat'"
        ></kg-ticket>
      </div>

      <ng-template #noTicketsTemplate>
        <div class="tickets-slider">
          <div class="tickets-slider-title">
            KinoGram<br />
            {{ 'Tickets.ItsIsNotOnlyACinema' | translate }}
          </div>
          <div class="fader" #sliderRef>
            <div
              [ngStyle]="{ opacity: opacities[idx] }"
              class="fader__slide"
              *ngFor="let src of sliderItems; let idx = index"
            >
              <img [src]="src" />
            </div>
            <div class="dots">
              <button
                (click)="slider.moveToIdx(i)"
                *ngFor="let slide of dotHelper; let i = index"
                [class]="'dot ' + (i === currentSlide ? 'active' : '')"
              ></button>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  `,
  styleUrls: ['./tickets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketsComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  @ViewChild('sliderRef') sliderRef!: ElementRef<HTMLElement>;
  @Output() onSelect: EventEmitter<Ticket> = new EventEmitter();

  @Output() onApplyVoucher: EventEmitter<string> = new EventEmitter();
  @Output() onRemoveVoucher: EventEmitter<AppliedVoucher> = new EventEmitter();

  @ViewSelectSnapshot(ReservationState.getTickets) ticketGroup!: Ticket[][];
  @ViewSelectSnapshot(ReservationState.getVouchers) vouchers!: AppliedVoucher[];
  @ViewSelectSnapshot(ReservationState.getScreeningItems) screeningItems!: ScreeningItemOutput[];
  @ViewSelectSnapshot(ReservationState.getPaymentId) paymentId!: string;

  showVoucherInput: boolean = true;
  InputType = InputType;
  form!: FormGroup;
  FormControls = FormControls;
  AlertType = AlertType;

  slider!: KeenSliderInstance;
  sliderItems: string[] = [
    'assets/img/slider/1.png',
    'assets/img/slider/2.png',
    'assets/img/slider/3.png',
    'assets/img/slider/4.png',
  ];
  opacities: number[] = [];
  dotHelper: Array<Number> = [];
  currentSlide: number = 0;
  isVoucherFromUrl = false;

  @Input() set voucher(voucher: string | null) {
    if (voucher) {
      this.showVoucherInput = true;
      this.isVoucherFromUrl = true;
      setTimeout(() => {
        this.form?.get(FormControls.VoucherNumber)?.setValue(voucher);
      }, 500);
    }
  }

  constructor(
    private reservationService: ReservationService,
    private fb: FormBuilder,
    private actions$: Actions,
    private cd: ChangeDetectorRef,
  ) {}

  get loading$(): Observable<boolean> {
    return this.reservationService.loading$;
  }

  get hasVoucherNumber(): string {
    return this.form.controls[FormControls.VoucherNumber].value;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      [FormControls.VoucherNumber]: [this.vouchers ? this.vouchers[0].voucherNumber : null],
    });

    if (this.hasVoucherNumber) {
      this.showVoucherInput = true;
      this.form.get(FormControls.VoucherNumber)?.disable();
    }

    if (this.paymentId) {
      this.form.get(FormControls.VoucherNumber)?.disable();
    }

    if (this.vouchers) {
      this.updateTickets();
    }

    this.actions$.pipe(ofActionCompleted(UpdateTickets), takeUntil(this.destroy$)).subscribe(() => {
      this.updateTickets();
    });
  }

  ngAfterViewInit(): void {
    this.initSlider();
  }

  updateTickets(): void {
    if (!this.vouchers) {
      this.ticketGroup.flat().forEach((ticket) => (ticket.hasVoucher = false));
      return;
    }
    for (const voucher of this.vouchers) {
      for (const ticket of this.ticketGroup.flat()) {
        if (voucher.screeningItemId === ticket.screeningItemId) {
          ticket.hasVoucher = true;
        }
      }
    }
  }

  trackByTicketIndex(index: number) {
    return index;
  }

  public resetVoucherNumberControl(): void {
    this.form.get(FormControls.VoucherNumber)?.setValue(null);
    this.form.get(FormControls.VoucherNumber)?.enable();
    this.showVoucherInput = false;
    if (this.ticketGroup.length > 1) {
      this.removeVoucher();
    }
  }

  removeVoucher(): void {
    if (this.vouchers) {
      this.onRemoveVoucher.emit(this.vouchers[0]);
    }
  }

  applyVoucher() {
    const value = this.form.controls[FormControls.VoucherNumber].value;
    if (!value) {
      return;
    }

    this.onApplyVoucher.emit(value.toUpperCase());
  }

  initSlider(): void {
    if (!this.sliderRef) {
      return;
    }
    this.slider = new KeenSlider(
      this.sliderRef.nativeElement,
      {
        loop: true,
        slides: this.sliderItems.length,
        defaultAnimation: {
          duration: 1500,
        },
        detailsChanged: (s) => {
          this.opacities = s.track.details.slides.map((slide) => slide.portion);
          this.cd.detectChanges();
        },
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel;
        },
      },
      [(slider) => this.applySliderFade(slider)],
    );
    this.dotHelper = [...Array(this.slider.track.details.slides.length).keys()];
  }

  applySliderFade(slider: KeenSliderInstance): void {
    let timeout: any;
    let mouseOver = false;
    function clearNextTimeout() {
      clearTimeout(timeout);
    }
    function nextTimeout() {
      clearTimeout(timeout);
      if (mouseOver) return;
      timeout = setTimeout(() => {
        slider.next();
      }, 1500);
    }
    slider.on('created', () => {
      slider.container.addEventListener('mouseover', () => {
        mouseOver = true;
        clearNextTimeout();
      });
      slider.container.addEventListener('mouseout', () => {
        mouseOver = false;
        nextTimeout();
      });
      nextTimeout();
    });
    slider.on('dragStarted', clearNextTimeout);
    slider.on('animationEnded', nextTimeout);
    slider.on('updated', nextTimeout);
  }

  ngOnDestroy() {
    this.destroy$?.next();
    this.destroy$?.complete();
    if (this.slider) this.slider.destroy();
  }
}
