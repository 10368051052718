import { RowOutput } from '@graphql/graphql-types';

export class ScreenRow implements RowOutput {
  coordinate?: number;
  id?: string;
  legend?: string;

  constructor(data: ScreenRow | RowOutput) {
    data ? Object.assign(this, data) : '';
  }
}
