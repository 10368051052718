import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { Config } from '@configs/config';
import { TagGroupOutput } from '@graphql/graphql-types';
import { generateRandomString } from '@helpers/generate-random-string.function';
import Utils from '@helpers/utils';
import { TranslateModule } from '@ngx-translate/core';
import { MovieTagPipe } from '@pipes/movie-tag.pipe';

@Component({
  selector: 'kg-auto-trailer',
  standalone: true,
  imports: [CommonModule, YouTubePlayerModule, MovieTagPipe, TranslateModule],
  template: `<div class="iframe-container" [class.has-special-tag]="tagGroups">
    <div class="movie-tag" *ngIf="tagGroups?.length">{{ tagGroups | movieTag | translate }}</div>
    <div [id]="randomId"></div>
  </div>`,
  styleUrls: ['./auto-trailer.component.scss'],
})
export class AutoTrailerComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() videoId!: string;
  @Input() tagGroups!: TagGroupOutput[];

  player!: YT.Player | null;
  readonly randomId: string = generateRandomString(32);

  Config = Config;

  ngAfterViewInit(): void {
    this.player = new YT.Player(this.randomId!, {
      videoId: this.videoId,
      playerVars: {
        loop: 1,
        autoplay: Utils.isMobileSafari() ? 0 : 1,
        playlist: this.videoId,
        playsinline: 1,
        controls: 1,
        modestbranding: 1,
        rel: 0,
      },
      events: {
        onReady: (e: YT.PlayerEvent) => {
          e.target.mute();
          if (Utils.isMobileSafari()) {
            return;
          }
          e.target.playVideo();
        },
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['videoId'] && changes['videoId'].currentValue && changes['videoId'].previousValue) {
      this.player?.mute();
      this.player?.loadVideoById(changes['videoId'].currentValue);
      if (Utils.isMobileSafari()) {
        this.player?.stopVideo();
      }
    }
  }

  ngOnDestroy(): void {
    this.player?.destroy();
    this.player = null;
  }
}
