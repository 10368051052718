import { RemoveVoucherFragment } from '@graphql/fragments/reservation/remove-voucher.fragment';
import { gql } from 'apollo-angular';

export const RemoveVoucherFromOrderDocument = gql`
  mutation removeVoucherFromOrder($orderId: String!, $voucherNumber: String!) {
    removeVoucherFromOrder(orderId: $orderId, voucherNumber: $voucherNumber) {
      ...RemoveVoucher
    }
  }
  ${RemoveVoucherFragment}
`;
