import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Breadcrumb } from '@interfaces/breadcrumb.interface';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { LoadingBarState } from '@ngx-loading-bar/core/loading-bar.state';
import { TranslateModule } from '@ngx-translate/core';
import { MovieTitlePipe } from '@pipes/movie-title.pipe';
import { LocalStorageService, SCREENING_URL_KEY } from '@services/local-storage.service';

@Component({
  selector: 'kg-breadcrumbs',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, MovieTitlePipe],
  template: `
    <nav aria-label="breadcrumbs">
      <ol class="breadcrumbs">
        <li class="breadcrumbs-item" *ngFor="let item of breadcrumbs; let last = last">
          <button
            class="breadcrumbs-link"
            [class.active]="item.active"
            [class.is-disabled]="item.isDisabled"
            *ngIf="!last"
            [disabled]="item.disabled"
            (click)="onRouteChange(item)"
          >
            {{ item.label | translate }}
          </button>
          <span class="breadcrumbs-separator" *ngIf="!last">/</span>
          <span class="breadcrumbs-label" [class.inactive]="item.disabled" *ngIf="last && !item.isMovieTitle">{{
            item.label | translate
          }}</span>
          <span class="breadcrumbs-label" [class.inactive]="item.disabled" *ngIf="last && item.isMovieTitle">{{
            item.movie! | movieTitle
          }}</span>
        </li>
      </ol>
    </nav>
  `,
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  @Input() breadcrumbs: Breadcrumb[] = [];

  constructor(
    private loadingBarService: LoadingBarService,
    private router: Router,
    private storage: LocalStorageService,
  ) {}

  onRouteChange(item: Breadcrumb): void {
    const loader: LoadingBarState = this.loadingBarService.useRef();
    loader.start();
    const filtersHistoryItem = this.storage.get(SCREENING_URL_KEY);
    if (item.isBack && filtersHistoryItem) {
      this.router.navigateByUrl(item.link + filtersHistoryItem);
    } else {
      this.router.navigate([item.link]);
    }
  }
}
