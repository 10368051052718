import { gql } from 'apollo-angular';

export const CreateOrderFragment = gql`
  fragment CreateOrder on OrderOutput {
    id
    dateTimeToLive
    screeningItems {
      id
      seatId
      screeningId
      ticketId
      seat
      row
      price
      voucherNumber
      name
      printoutName
    }
  }
`;
