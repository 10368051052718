import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SpeakingType } from '@enums/speaking-type.enum';
import { Screening } from '@models/screening.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'kg-screening-language',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  template: `
    <span
      class="screening-language__language"
      [class.screening-language__movie-presence]="useMoviePresence"
      [class.screening-language__seats-presence]="useSeatsPresence"
      [class.screening-language__screening-list-presence]="useScreeningListPresence"
    >
      <ng-container [ngSwitch]="screening.speakingType">
        <ng-container *ngSwitchCase="SpeakingType.DUB">
          <span *ngIf="screening.language">{{ screening.language }} &centerdot; </span>
          <span *ngIf="screening.speakingType">({{ screening.speakingType }}) &centerdot; </span>
          <span *ngIf="screening.printType">{{ screening.printType }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="SpeakingType.ORG">
          <span *ngIf="screening.language">{{ screening.language }} &centerdot; </span>
          <span *ngIf="screening.subtitles"
            >({{ 'Screening.Sub' | translate }} &centerdot; {{ screening.subtitles }}) &centerdot;
          </span>
          <span *ngIf="screening.printType"> {{ screening.printType }}</span>
        </ng-container>
      </ng-container>
    </span>
  `,
  styleUrls: ['./screening-language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreeningLanguageComponent {
  @Input() screening!: Screening;
  @Input() useMoviePresence: boolean = false;
  @Input() useSeatsPresence: boolean = false;
  @Input() useScreeningListPresence: boolean = false;
  SpeakingType = SpeakingType;
}
