import { ScreenFragment } from '@graphql/fragments/screen/screen.fragment';
import { gql } from 'apollo-angular';

export const GetScreenByIdDocument = gql`
  query getScreenById($screenId: String!) {
    getScreenById(screenId: $screenId) {
      ...Screen
    }
  }
  ${ScreenFragment}
`;
