import { CommonModule, CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Config } from '@configs/config';
import { Routes } from '@configs/routes';
import { AlertType } from '@enums/alert-type.enum';
import { TapeType } from '@enums/tape-type.enum';
import { environment } from '@env';
import {
  OrderEditEntryInput,
  OrderEntryInput,
  OrderOutput,
  ScreeningItemOutput,
  ScreeningOutput,
  TicketOutput,
} from '@graphql/graphql-types';
import Utils from '@helpers/utils';
import { Breadcrumb } from '@interfaces/breadcrumb.interface';
import { SeatGrid } from '@interfaces/seat-grid.interface';
import { Movie } from '@models/movie.model';
import { Order } from '@models/order.model';
import { ScreenSeat } from '@models/screen-seat.model';
import { Screening } from '@models/screening.model';
import { Ticket } from '@models/ticket.model';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SelectSnapshot, ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Store } from '@ngxs/store';
import { CustomCurrencyPipe } from '@pipes/custom-currency.pipe';
import { ScreeningDatePipe } from '@pipes/screening-date.pipe';
import { TicketsAmountPipe } from '@pipes/tickets-amount.pipe';
import { NotificationService } from '@services/notification.service';
import { ReservationTimerService } from '@services/reservation-timer.service';
import { ReservationService } from '@services/reservation.service';
import { SeatGridService } from '@services/seat-grid.service';
import { SEOService } from '@services/seo.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Observable, map, takeUntil, tap } from 'rxjs';
import { AlertComponent } from 'src/app/_components/alert/alert.component';
import { AutoTrailerComponent } from 'src/app/_components/auto-trailer/auto-trailer.component';
import { BreadcrumbsComponent } from 'src/app/_components/breadcrumbs/breadcrumbs.component';
import { CardWithIconComponent } from 'src/app/_components/card-with-icon/card-with-icon.component';
import { DestroyableComponent } from 'src/app/_components/destroyable.component';
import { MovieOverlayComponent } from 'src/app/_components/movie-overlay/movie-overlay.component';
import { ReservationInfoCardComponent } from 'src/app/_components/reservation-info-card/reservation-info-card.component';
import { ReservationTimerComponent } from 'src/app/_components/reservation-timer/reservation-timer.component';
import { ScreeningLanguageComponent } from 'src/app/_components/screening-language/screening-language.component';
import {
  ScreeningSelectComponent,
  ScreeningSelectOption,
} from 'src/app/_components/screening-select/screening-select.component';
import { SeatGridComponent } from 'src/app/_components/seat-grid/seat-grid.component';
import { StickySummaryComponent } from 'src/app/_components/sticky-summary/sticky-summary.component';
import { TapeComponent } from 'src/app/_components/tape/tape.component';
import { TicketsComponent } from 'src/app/_components/tickets/tickets.component';
import { OpenReservation } from 'src/app/_store/actions/open-reservation.action';
import { UpdateMovieTitle } from 'src/app/_store/actions/update-movie-title.action';
import { UpdateScreenRoomNumber } from 'src/app/_store/actions/update-screen-room-number.action';
import { UpdateScreeningTime } from 'src/app/_store/actions/update-screening-time.action';
import { UpdateTickets } from 'src/app/_store/actions/update-tickets.action';
import { UpdateVoucher } from 'src/app/_store/actions/update-vouchers.action';
import { AppliedVoucher, ReservationOrder } from 'src/app/_store/models/reservation.model';
import { ReservationState } from 'src/app/_store/reservation.state';
import { RouteData } from 'src/app/_types/route-data.type';

export const VOUCHER_PARAM_NAME = 'voucher';

@Component({
  selector: 'kg-places',
  standalone: true,
  imports: [
    CommonModule,
    ScreeningLanguageComponent,
    ReservationTimerComponent,
    SeatGridComponent,
    BreadcrumbsComponent,
    AutoTrailerComponent,
    ScreeningSelectComponent,
    TicketsComponent,
    TicketsAmountPipe,
    RouterModule,
    TranslateModule,
    CardWithIconComponent,
    AngularSvgIconModule,
    MovieOverlayComponent,
    StickySummaryComponent,
    ReservationInfoCardComponent,
    AlertComponent,
    TapeComponent,
  ],
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.scss'],
  providers: [CurrencyPipe, ScreeningDatePipe, CustomCurrencyPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacesComponent extends DestroyableComponent implements OnInit {
  @ViewChild(ScreeningSelectComponent) screeningSelect!: ScreeningSelectComponent;
  @ViewChild(TicketsComponent) kgTickets!: TicketsComponent;

  movie!: Movie;
  screening!: Screening;
  breadcrumbs: Breadcrumb[] = [];
  tickets!: Ticket[];
  voucherFromUrl: string | null = null;

  Config = Config;
  Utils = Utils;
  Routes = Routes;
  AlertType = AlertType;
  TapeType = TapeType;

  movieScreeningList: ScreeningSelectOption[] = [];
  currentMovieScreening: string = '';
  currentMovieScreeningDate: string = '';

  @ViewSelectSnapshot(ReservationState.getReservationOrder) reservationOrder!: ReservationOrder;
  @ViewSelectSnapshot(ReservationState.getPaymentId) paymentId!: string;
  @SelectSnapshot(ReservationState.getReservationOrder) order!: ReservationOrder;
  @SelectSnapshot(ReservationState.getTickets) orderTickets!: Ticket[][];
  @SelectSnapshot(ReservationState.getVouchers) vouchers!: AppliedVoucher[];

  get canBuyTicket(): boolean {
    return Utils.canBuyTicket(this.screening?.saleTimeTo);
  }

  get showCanBuyTicketAlert(): boolean {
    return Utils.showBuyTicketAlert(this.screening?.screeningTimeFrom, this.screening?.saleTimeTo);
  }

  constructor(
    private loadingBarService: LoadingBarService,
    private route: ActivatedRoute,
    private t: TranslateService,
    private router: Router,
    private reservationTimerService: ReservationTimerService,
    private store: Store,
    private reservationService: ReservationService,
    private seatGridService: SeatGridService,
    private cd: ChangeDetectorRef,
    private n: NotificationService,
    private seoService: SEOService,
  ) {
    super();
    this.init();
  }

  ngOnInit(): void {
    if (!this.vouchers?.length && this.orderTickets?.length) {
      this.initVoucherFromUrl();
    }
  }

  init(): void {
    const loader = this.loadingBarService.useRef();
    this.route.data.subscribe({
      next: (data: RouteData) => {
        const { screening } = data;
        if (screening) {
          this.movie = new Movie(screening.movie!);
          const title = this.t.instant('Seo.PlacesMetaTitle');
          this.seoService.updateTitle(`${environment.appName} | ${title}`);
          this.screening = new Screening(screening);
          this.getMovieScreeningList(this.movie.id!);
          this.breadcrumbs = this.createBreadcrumbs();
          this.watchModalAction();
          loader.set(100);
          loader.stop();
          this.tickets = this.orderTickets.flat();
        }
      },
    });
  }

  getVoucherFromUrl(): string | null {
    const params = new URLSearchParams(window.location.search);
    return params.get(VOUCHER_PARAM_NAME);
  }

  initVoucherFromUrl(): void {
    const voucherFromUrl = this.getVoucherFromUrl();
    if (voucherFromUrl) {
      this.voucherFromUrl = voucherFromUrl;
    }
  }

  createBreadcrumbs(): Breadcrumb[] {
    return [
      {
        label: 'Routing.Repertoire',
        link: Routes.Screening,
        isBack: true,
      },
      {
        label: 'Routing.Place',
        link: this.router.url,
        active: true,
        disabled: true,
      },
      {
        label: 'Routing.Payment',
        link: '',
        isDisabled: true,
        disabled: true,
      },
      {
        label: 'Routing.Ticket',
        link: '',
        disabled: true,
      },
    ];
  }

  watchModalAction(): void {
    this.reservationService.modalCancelled$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.screeningSelect.selectOption(this.screening.id));

    this.reservationService.modalAccepted$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.kgTickets.resetVoucherNumberControl();
    });
  }

  getMovieScreeningList(movieId: string): void {
    this.reservationService
      .fetchScreeningListForSelect(movieId)
      .pipe(
        map((list: ScreeningOutput[]) => {
          const sortedList = Utils.sortByScreeningTimeFrom(list);
          return this.getFormattedOptions(sortedList);
        }),
        tap((options: ScreeningSelectOption[]) => this.getDataForSelect(options)),
      )
      .subscribe({
        error: () => {},
        complete: () => {
          this.cd.markForCheck();
        },
      });
  }

  getFormattedOptions(list: ScreeningOutput[]): ScreeningSelectOption[] {
    return list.map(({ id, screeningTimeFrom }) => ({
      value: id!,
      label: screeningTimeFrom,
    }));
  }

  getDataForSelect(options: ScreeningSelectOption[]): void {
    this.movieScreeningList = options;
    if (this.showCanBuyTicketAlert) {
      this.movieScreeningList.unshift({
        value: this.router.url.split('/')[3],
        label: this.screening.screeningTimeFrom,
      });
    }
    for (const { value, label } of options) {
      if (value === this.screening.id) {
        this.currentMovieScreening = value;
        this.currentMovieScreeningDate = label;
        break;
      }
    }
  }

  getTicketsAsObservable(orderId: string): Observable<boolean> {
    return new Observable((subscriber) => {
      this.reservationService.fetchAvailableTicketsByOrder(orderId).subscribe({
        next: (tickets: TicketOutput[]) => {
          this.tickets = tickets.map((ticket: TicketOutput) => new Ticket(ticket));
          const grouppedTickets = Utils.groupTickets(this.tickets);
          this.store.dispatch(new UpdateTickets(grouppedTickets));

          for (const item of this.order.screeningItems) {
            for (const ticket of grouppedTickets.flat()) {
              if (ticket.screeningItemId === item.id && !item.ticketId) {
                item.ticketId = ticket.id;
                item.price = ticket.priceWithMandatoryExtraFees;
                ticket.seatId = item.seatId!;
                break;
              }
            }
          }
          subscriber.next(true);
          subscriber.complete();
        },
        error: () => {
          this.reservationService.setLoader(false);
        },
        complete: () => {},
      });
    });
  }

  onScreeningSelect(screeningId: string): void {
    if (this.order && this.order.screeningId !== screeningId) {
      this.reservationService.showCancelReservationConfirm(screeningId);
    } else {
      this.router.navigate([`${Routes.Reservation.Places}/${screeningId}`]);
    }
  }

  onSeatSelect(seat: SeatGrid): void {
    if (this.store.snapshot().reservation.started) {
      this.reservationService.setLoader(true);
      const orderEditItems = this.createEditOrderInput(seat.seatWrapper.seats);
      this.updateOrder(orderEditItems, seat.seatWrapper.isOccupied, false);
      return;
    }

    const orderItems = this.createOrderInput(seat.seatWrapper.seats);
    this.createOrder(orderItems);
  }

  onTicketSelect(ticket: Ticket): void {
    this.reservationService.setLoader(true);
    const item = this.order.screeningItems.find((s: ScreeningItemOutput) => s.id === ticket.screeningItemId)!;
    item.ticketId = ticket.id;
    item.price = ticket.priceWithMandatoryExtraFees;
    this.updateOrder(this.order.screeningItems, false, true);
  }

  onApplyVoucher(voucherNumber: string): void {
    this.reservationService.setLoader(true);
    this.reservationService.applyVoucherToOrder(this.order.orderId, voucherNumber).subscribe({
      next: (orderOutput: OrderOutput) => {
        this.order.screeningItems = orderOutput.screeningItems!;
        const vouchers: AppliedVoucher[] = [];
        for (const item of this.order.screeningItems) {
          if (item.voucherNumber === voucherNumber) {
            vouchers.push({
              voucherNumber,
              row: item.row!,
              seat: item.seat!,
              screeningItemId: item.id!,
              price: item.price!,
              seatId: item.seatId!,
            });
          }
        }
        this.store
          .dispatch(new UpdateVoucher(vouchers.length > 0 ? vouchers : null))
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            this.updateScreeningItems(this.order.screeningItems);
            if (data.reservation.vouchers) {
              this.n.onSuccess(this.t.instant('Vouchers.VoucherAddedSuccess'));
            } else {
              this.n.onWarning(this.t.instant('Vouchers.VoucherWrongNumber'));
            }
          });
      },
      error: () => {
        this.reservationService.setLoader(false);
      },
      complete: () => {
        this.reservationService.setLoader(false);
      },
    });
  }

  onRemoveVoucher(voucher: AppliedVoucher): void {
    this.reservationService.setLoader(true);
    this.reservationService.removeVoucherFromOrder(this.order.orderId, voucher.voucherNumber).subscribe({
      next: (orderOutput: OrderOutput) => {
        this.order.screeningItems = orderOutput.screeningItems!;
        this.store
          .dispatch(new UpdateVoucher(null))
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.updateScreeningItems(this.order.screeningItems);
            this.kgTickets.resetVoucherNumberControl();
            this.n.onSuccess(this.t.instant('Vouchers.VoucherRemovedSuccess'));
          });
        this.initVoucherFromUrl();
      },
      error: () => {
        this.reservationService.setLoader(false);
      },
      complete: () => {
        this.reservationService.setLoader(false);
      },
    });
  }

  updateScreeningItems(screeningItems: ScreeningItemOutput[]): void {
    for (const item of screeningItems) {
      for (const ticket of this.tickets) {
        if (ticket.screeningItemId === item.id && ticket.id === item.ticketId) {
          ticket.price = item.price!;
        }
      }
    }
    const grouppedTickets = Utils.groupTickets(this.tickets);
    this.store.dispatch(new UpdateTickets(grouppedTickets));
    this.reservationService.setLoader(false);
  }

  createOrderInput(seats: ScreenSeat[]): ScreeningItemOutput[] {
    return seats.map((seat: ScreenSeat) => ({
      screeningId: this.screening.id,
      seatId: seat.id!,
    }));
  }

  createEditOrderInput(seats: ScreenSeat[]): OrderEditEntryInput[] {
    return seats.map((seat: ScreenSeat) => ({
      screeningId: this.screening.id,
      seatId: seat.id!,
      id: this.order.screeningItems.find((item: ScreeningItemOutput) => item.seatId === seat.id)?.id,
      ticketId: this.order.screeningItems.find((item: ScreeningItemOutput) => item.seatId === seat.id)?.ticketId,
    }));
  }

  createOrder(orderItems: ScreeningItemOutput[]): void {
    this.reservationService
      .createOrder(orderItems as OrderEntryInput[])
      .pipe(map((o: OrderOutput) => new Order(o)))
      .subscribe({
        next: (order: Order) => {
          const reservationOrder = new ReservationOrder({
            orderId: order.id!,
            screeningId: this.screening.id,
            movieId: this.movie.id!,
            screeningItems: order.screeningItems!,
            dateTimeToLive: order.dateTimeToLive!.toString(),
          });
          this.store.dispatch(new UpdateScreeningTime(this.currentMovieScreeningDate));
          this.store.dispatch(new UpdateMovieTitle(this.movie.title!, this.movie.originalTitle!));
          this.store.dispatch(new UpdateScreenRoomNumber(this.screening.screen!.number!));
          this.store.dispatch(new OpenReservation(reservationOrder)).subscribe((data) => {
            this.reservationTimerService.startCountdown(Utils.getReservationTimeDiff(order.dateTimeToLive!.toString()));
            this.getTicketsAsObservable(order.id!)
              .pipe(takeUntil(this.destroy$))
              .subscribe((response: boolean) => {
                if (response) {
                  this.updateOrder(data.reservation.order.screeningItems, false, true);
                }
              });
          });
          this.initVoucherFromUrl();
        },
        error: () => {
          this.reservationService.setLoader(false);
          this.updateSeatGrid();
        },
      });
  }

  updateOrder(orderItems: ScreeningItemOutput[], isOccupied: boolean, ticketUpdate: boolean): void {
    let orderEntries = ticketUpdate
      ? (orderItems as OrderEditEntryInput[])
      : ([...orderItems, ...this.order.screeningItems] as OrderEditEntryInput[]);

    if (isOccupied) {
      orderEntries = orderEntries.filter((entry) => {
        return !orderItems.some((orderItem) => orderItem.id === entry.id);
      });
    }
    if (orderEntries.length > 0) {
      this.editOrder(this.getOrderEntriesToUpdate(orderEntries));
    } else {
      this.deleteOrder();
    }
  }

  getOrderEntriesToUpdate(orderEntries: OrderEditEntryInput[]): OrderEditEntryInput[] {
    return orderEntries.map((o: OrderEditEntryInput) => ({
      id: o.id,
      screeningId: o.screeningId,
      ticketId: o.ticketId,
      seatId: o.seatId,
    }));
  }

  editOrderAfterEdit(orderItems: OrderEditEntryInput[]): void {
    this.reservationService.editOrder(this.order.orderId, this.getOrderEntriesToUpdate(orderItems)).subscribe({
      next: (orderOutput: OrderOutput) => {
        this.order.screeningItems = orderOutput.screeningItems!;

        this.getTicketsAsObservable(this.order.orderId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.updateScreeningItems(this.order.screeningItems);
          });
      },
      error: () => {
        this.reservationService.setLoader(false);
      },
      complete: () => {
        this.updateSeatGrid();
      },
    });
  }

  editOrder(orderItems: OrderEditEntryInput[]): void {
    this.reservationService.editOrder(this.order.orderId, orderItems).subscribe({
      next: (orderOutput: OrderOutput) => {
        this.order.screeningItems = orderOutput.screeningItems!;
        this.getTicketsAsObservable(this.order.orderId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            if (data) {
              this.editOrderAfterEdit(this.order.screeningItems as OrderEditEntryInput[]);
            }
          });
      },
      error: () => {
        this.reservationService.setLoader(false);
      },
    });
  }

  deleteOrder(): void {
    this.reservationService.deleteOrder(this.order.orderId).subscribe({
      next: () => {
        this.updateSeatGrid();
        this.reservationService.resetStore();
        this.reservationTimerService.stopCountdown();
      },
      error: (error) => {
        this.n.onError(error.message);
        this.reservationService.setLoader(false);
      },
      complete: () => {
        this.reservationService.setLoader(false);
      },
    });
  }

  updateSeatGrid(): void {
    this.seatGridService.getData(this.screening.screen!, this.screening.id);
  }

  goToPaymentForm(): void {
    const loader = this.loadingBarService.useRef();
    loader.start();
    this.router.navigate([`${Routes.Payment.Form}/${this.screening.id}`]);
  }
}
