import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ScreeningItemOutput } from '@graphql/graphql-types';
import { Ticket } from '@models/ticket.model';
import { TranslateModule } from '@ngx-translate/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { TicketsAmountPipe } from '@pipes/tickets-amount.pipe';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ReservationState } from 'src/app/_store/reservation.state';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'kg-sticky-summary',
  standalone: true,
  imports: [CommonModule, TranslateModule, TicketsAmountPipe, AngularSvgIconModule, LoaderComponent],
  template: `
    <div class="sticky-summary" [class.is-visible]="orderTickets.length > 0">
      <div class="container">
        <div class="sticky-summary-header">
          <div class="sticky-summary-title">{{ 'Payment.ToPay' | translate }}:</div>
          <div class="sticky-summary-amount">{{ screeningItems | ticketsAmount }}</div>
        </div>
        <button class="btn btn-primary btn-block btn-order" (click)="onButtonClick.emit()" [disabled]="isDisabled">
          {{ title | translate }}
          <svg-icon class="hidden-mobile" *ngIf="!isLoading" src="assets/img/icons/chevron-right.svg"></svg-icon>
          <kg-loader class="hidden-mobile" *ngIf="isLoading" [center]="false" [paymentLoader]="true"></kg-loader>
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./sticky-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StickySummaryComponent {
  @Input() orderTickets: Ticket[][] = [];
  @Input() title!: string;
  @Input() isLoading?: boolean = false;
  @Input() buttonDisabled: boolean = false;

  @Output() onButtonClick: EventEmitter<null> = new EventEmitter();

  @ViewSelectSnapshot(ReservationState.getScreeningItems) screeningItems!: ScreeningItemOutput[];

  get isDisabled(): boolean {
    return !this.orderTickets.length || this.buttonDisabled;
  }
}
