export const Routes = {
  Screening: '/screening/list',
  Movie: '/screening/movie',
  Reservation: {
    Places: '/reservation/places',
  },
  Payment: {
    Form: '/payment/form',
    Success: '/payment/status/success',
    Fail: '/payment/status/fail',
    Status: '/payment/status',
  },
  NotFound: '/not-found',
  TechnicalBreak: '/technical-break',
};
