import { Pipe, PipeTransform } from '@angular/core';
import { TagGroupOutput } from '@graphql/graphql-types';

@Pipe({
  name: 'movieTag',
  standalone: true,
})
export class MovieTagPipe implements PipeTransform {
  transform(tagGroups: TagGroupOutput[] | undefined): string {
    let movieTag = '';
    if (tagGroups) {
      for (const group of tagGroups) {
        if (group.tags?.length) {
          for (const tag of group.tags) {
            movieTag = tag.symbol as string;
          }
        }
      }
    }
    return `MovieTag.${movieTag}`;
  }
}
