import { Pipe, PipeTransform } from '@angular/core';
import { ScreeningItemOutput } from '@graphql/graphql-types';
import { CustomCurrencyPipe } from './custom-currency.pipe';

@Pipe({
  name: 'ticketsAmount',
  standalone: true,
})
export class TicketsAmountPipe implements PipeTransform {
  totalPrice!: number;
  constructor(private customCurrencyPipe: CustomCurrencyPipe) {}
  transform(screeningItems: ScreeningItemOutput[]): string {
    this.totalPrice = 0;
    const items = screeningItems || [];
    items.forEach((item) => (this.totalPrice += item ? item.price! : 0));
    return this.customCurrencyPipe.transform(this.totalPrice);
  }
}
