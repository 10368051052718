import { LocationStrategy, ViewportScroller } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
// import '@angular/common/locales/global/en';
import '@angular/common/locales/global/pl';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, Scroll } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env';
import { appInitializerFactory } from '@factories/app-initializer.factory';
import { HttpLoaderFactory } from '@factories/multi-translate-http-loader.factory';
import { PreserveVoucherQueryLocationStrategy } from '@helpers/preserve-voucher-query-param';
import { LOADING_BAR_CONFIG, LoadingBarModule } from '@ngx-loading-bar/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { AppInjector } from '@services/app-injector.service';
import { LanguageService } from '@services/language.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NZ_I18N, en_US, pl_PL } from 'ng-zorro-antd/i18n';
import { CookieService } from 'ngx-cookie-service';
import { filter } from 'rxjs';
import { BackToReservationComponent } from './_components/back-to-reservation/back-to-reservation.component';
import { EndSessionComponent } from './_components/end-session/end-session.component';
import { GraphQLModule } from './_graphql/graphql.module';
import { ReservationState } from './_store/reservation.state';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    AngularSvgIconModule.forRoot(),
    GraphQLModule,
    LoadingBarModule,
    NgxsModule.forRoot([ReservationState]),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsSelectSnapshotModule.forRoot(),
    EndSessionComponent,
    BackToReservationComponent,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    CookieService,
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useFactory: (lang: LanguageService) => {
        return lang.getCurrentLanguage();
      },
    },
    {
      provide: NZ_I18N,
      useFactory: (localId: string) => {
        switch (localId) {
          case 'en':
            return en_US;
          default:
            return pl_PL;
        }
      },
      deps: [LOCALE_ID],
    },
    { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 100 } },
    { provide: LocationStrategy, useClass: PreserveVoucherQueryLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector, router: Router, viewportScroller: ViewportScroller) {
    AppInjector.setInjector(injector);
    router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe((e) => {
      if (e.position) {
        setTimeout(() => {
          viewportScroller.scrollToPosition(e.position!);
        });
      } else if (e.anchor) {
        setTimeout(() => {
          viewportScroller.scrollToAnchor(e.anchor!);
        });
      }
    });
  }
}
