<div class="reservation-info-panel">
  <div class="reservation-info-panel-poster hidden-mobile">
    <div
      class="reservation-info-panel-picture"
      [style.background-image]="'url(' + screening.movie.posters![0] + ')'"
    ></div>
    <div class="reservation-info-panel-overlay"></div>
  </div>
  <div class="reservation-info-panel-content">
    <div class="screening-select-wrapper">
      <kg-auto-trailer
        class="hidden-web"
        *ngIf="screening.movie.trailers?.length"
        [videoId]="Utils.getVideoIdFromUrl(screening.movie!.trailers![0])"
        [class.hidden-mobile]="hideVideoMobile"
        [tagGroups]="screening.movie.tagGroups!"
      ></kg-auto-trailer>
    </div>

    <div class="reservation-info-atmos" *ngIf="screening.soundType && screening.soundType === SoundType.SYSTEM_ATMOS">
      <svg-icon src="assets/img/icons/atmos.svg"></svg-icon>
    </div>
    <h1 class="reservation-movie-title" [innerText]="screening.movie | movieTitle"></h1>
    <div class="movie-header-info-wrapper" [class.hidden-mobile]="!showMobile">
      <div class="movie-header-info">
        <span class="info">
          <span *ngIf="screening.movie.yearOfProduction">{{ screening.movie.yearOfProduction }}, </span>
          <kg-movie-country [countries]="screening.movie.country!"></kg-movie-country>
          <span *ngFor="let genre of screening.movie?.genres; last as isLast">
            {{
              Utils.hasTranslation('MovieCategory.' + genre.name, t)
                ? ('MovieCategory.' + genre.name | translate)
                : genre.name
            }}<span *ngIf="(screening.movie?.genres)!.length > 0 && !isLast">, </span>
          </span>
        </span>
        <div class="hidden-web" *ngIf="showTimerMobile">
          <ng-container [ngTemplateOutlet]="timerTemplate"></ng-container>
        </div>
      </div>
      <ng-container
        [ngTemplateOutlet]="screeningLanguageTemplate"
        [ngTemplateOutletContext]="{ screening: screening, seatPresence: false, moviePresence: true }"
      >
      </ng-container>
    </div>

    <ng-content select="[screening-select]"></ng-content>

    <div class="reservation-info-panel-details-title">{{ 'Movie.Details' | translate }}</div>
    <kg-card-iconed [class.hidden-mobile]="!showMobile">
      <svg-icon icon src="assets/img/icons/location.svg"></svg-icon>
      <div title>{{ 'General.AddressName' | translate }}</div>
      <div body>
        <a [href]="Config.PLACE_MAPS_ADDRESS" target="_blank" class="address-location">{{
          'General.AddressLocation' | translate
        }}</a>
      </div>
    </kg-card-iconed>

    <kg-card-iconed [class.hidden-mobile]="!showMobile" *ngIf="showParkingInfo">
      <svg-icon icon src="assets/img/icons/car.svg"></svg-icon>
      <div title>{{ 'Reservation.ParkingTitle' | translate }}</div>
      <div body>
        {{ 'Reservation.ParkingInfo' | translate }}
      </div>
    </kg-card-iconed>

    <kg-card-iconed [class.hidden-mobile]="!showMobile" *ngIf="showPathInfo">
      <svg-icon icon src="assets/img/icons/walk.svg"></svg-icon>
      <div title>{{ 'Reservation.HowToGoTitle' | translate }}</div>
      <div body>
        {{ 'Reservation.HowToGoInfo' | translate }}
      </div>
    </kg-card-iconed>

    <kg-card-iconed [class.hidden-mobile]="!showMobile" *ngIf="showScreeningTimeFrom">
      <svg-icon icon src="assets/img/icons/calendar.svg"></svg-icon>
      <div title>{{ screening.screeningTimeFrom | screeningDatePipe }}</div>
      <div body>
        {{ 'Reservation.Room' | translate }} N<span class="fixed-underline">o</span>{{ screening.screen?.number }}
      </div>
    </kg-card-iconed>

    <ng-content select="[add-to-calendar]"></ng-content>
  </div>
</div>

<ng-template
  #screeningLanguageTemplate
  let-screening="screening"
  let-seatPresence="seatPresence"
  let-moviePresence="moviePresence"
>
  <kg-screening-language
    [screening]="screening"
    [useSeatsPresence]="seatPresence"
    [useMoviePresence]="moviePresence"
  ></kg-screening-language>
</ng-template>

<ng-template #timerTemplate>
  <kg-reservation-timer></kg-reservation-timer>
</ng-template>
