import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { OpenReservation } from './actions/open-reservation.action';
import { ResetReservation } from './actions/reset-reservation.action';
import { UpdateMovieTitle } from './actions/update-movie-title.action';
import { UpdateOrderUserData } from './actions/update-order-user-data.action';
import { UpdatePaymentId } from './actions/update-payment-id.action';
import { UpdateScreenRoomNumber } from './actions/update-screen-room-number.action';
import { UpdateScreeningTime } from './actions/update-screening-time.action';
import { UpdateSession } from './actions/update-session.action';
import { UpdateTickets } from './actions/update-tickets.action';
import { UpdateTimer } from './actions/update-timer.action';
import { UpdateVoucher } from './actions/update-vouchers.action';
import { Reservation } from './models/reservation.model';

export const REMAINING_TIME: string = '25:00';

const defaultState = {
  endSession: false,
  order: null,
  remainingTime: REMAINING_TIME,
  started: false,
  tickets: [],
  vouchers: null,
  screeningTimeFrom: null,
  movieTitle: null,
  movieOriginalTitle: null,
  screenRoomNumber: null,
  orderUserData: null,
  paymentId: null,
};

@State<Reservation>({
  name: 'reservation',
  defaults: defaultState,
})
@Injectable()
export class ReservationState {
  @Action(OpenReservation)
  start(ctx: StateContext<Reservation>, { order }: Reservation) {
    const state = ctx.getState();
    ctx.patchState({ ...state, started: true, order });
  }

  @Action(UpdateSession)
  updateSession(ctx: StateContext<Reservation>, { closed }: UpdateSession) {
    const state = ctx.getState();
    ctx.setState({ ...state, endSession: closed });
  }

  @Action(UpdateTimer)
  updateTimer(ctx: StateContext<Reservation>, { countdownTime }: UpdateTimer) {
    const state = ctx.getState();
    ctx.patchState({ ...state, remainingTime: countdownTime });
  }

  @Action(ResetReservation)
  resetReservation(ctx: StateContext<Reservation>) {
    const state = ctx.getState();
    ctx.setState({ ...state, ...defaultState });
  }

  @Action(UpdateTickets)
  updateTickets(ctx: StateContext<Reservation>, { tickets }: UpdateTickets) {
    const state = ctx.getState();
    ctx.setState({ ...state, tickets });
  }

  @Action(UpdateVoucher)
  updateVouchers(ctx: StateContext<Reservation>, { vouchers }: UpdateVoucher) {
    const state = ctx.getState();
    ctx.setState({ ...state, vouchers });
  }

  @Action(UpdateMovieTitle)
  updateMovieTitle(ctx: StateContext<Reservation>, { movieTitle, movieOriginalTitle }: UpdateMovieTitle) {
    const state = ctx.getState();
    ctx.setState({ ...state, movieTitle, movieOriginalTitle });
  }

  @Action(UpdateScreeningTime)
  updateScreeningTime(ctx: StateContext<Reservation>, { screeningTimeFrom }: UpdateScreeningTime) {
    const state = ctx.getState();
    ctx.setState({ ...state, screeningTimeFrom });
  }

  @Action(UpdateScreenRoomNumber)
  updateScreenRoomNumber(ctx: StateContext<Reservation>, { screenRoomNumber }: UpdateScreenRoomNumber) {
    const state = ctx.getState();
    ctx.setState({ ...state, screenRoomNumber });
  }

  @Action(UpdateOrderUserData)
  updateOrderUserData(ctx: StateContext<Reservation>, { orderUserData }: UpdateOrderUserData) {
    const state = ctx.getState();
    ctx.setState({ ...state, orderUserData });
  }

  @Action(UpdatePaymentId)
  updatePaymentId(ctx: StateContext<Reservation>, { paymentId }: UpdatePaymentId) {
    const state = ctx.getState();
    ctx.setState({ ...state, paymentId });
  }

  @Selector()
  static isReservationStarted(state: Reservation) {
    return state.started;
  }

  @Selector()
  static getReservationScreeningId(state: Reservation) {
    return state.order?.screeningId;
  }

  @Selector()
  static getRemainingTime(state: Reservation) {
    return state.remainingTime;
  }

  @Selector()
  static getReservationOrder(state: Reservation) {
    return state.order;
  }

  @Selector()
  static getTickets(state: Reservation) {
    return state.tickets || [];
  }

  @Selector()
  static getVouchers(state: Reservation) {
    return state.vouchers;
  }

  @Selector()
  static getScreeningItems(state: Reservation) {
    return state.order?.screeningItems;
  }

  @Selector()
  static getScreeningTime(state: Reservation) {
    return state.screeningTimeFrom;
  }

  @Selector()
  static getMovieTitle(state: Reservation) {
    return {
      movieTitle: state.movieTitle,
      movieOriginalTitle: state.movieOriginalTitle,
    };
  }

  @Selector()
  static getScreenRoomNumber(state: Reservation) {
    return state.screenRoomNumber;
  }

  @Selector()
  static getOrderUserData(state: Reservation) {
    return state.orderUserData;
  }

  @Selector()
  static getPaymentId(state: Reservation) {
    return state.paymentId;
  }

  @Selector()
  static getDateTimeToLive(state: Reservation) {
    return state.order?.dateTimeToLive;
  }
}
