<kg-movie-overlay [poster]="movie.posters![0]" [isOrderPage]="true"></kg-movie-overlay>
<div class="content content-relative" [class.sticky-summary-visible]="orderTickets.length">
  <div class="container container-xl container-relative">
    <div class="breadcrumbs-wrapper">
      <kg-breadcrumbs [breadcrumbs]="breadcrumbs"></kg-breadcrumbs>
      <div class="hidden-mobile timer-wrapper-web">
        <kg-reservation-timer></kg-reservation-timer>
      </div>
    </div>
    <div class="payment-alert hidden-mobile">
      <ng-container [ngTemplateOutlet]="paymentStartedTemplate"></ng-container>
      <ng-container [ngTemplateOutlet]="screeningStartedTemplate"></ng-container>
      <ng-container [ngTemplateOutlet]="screeningTimeExceededTemplate"></ng-container>
    </div>

    <div class="reservation-wrapper">
      <div class="reservation">
        <div class="reservation-screening-panel">
          <kg-reservation-info-card
            [hideVideoMobile]="true"
            [showMobile]="true"
            [showDetails]="false"
            [screening]="screening"
          >
            <kg-screening-select
              [disabled]="!!paymentId || !canBuyTicket"
              screening-select
              [options]="movieScreeningList"
              [selectedOption]="currentMovieScreening"
              (onChange)="onScreeningSelect($event)"
            ></kg-screening-select>
          </kg-reservation-info-card>
        </div>
        <div class="reservation-places-panel">
          <div class="hidden-web">
            <ng-container [ngTemplateOutlet]="paymentStartedTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="screeningStartedTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="screeningTimeExceededTemplate"></ng-container>
          </div>

          <div class="trailer-wrapper">
            <kg-auto-trailer
              *ngIf="screening.movie.trailers?.length"
              [videoId]="Utils.getVideoIdFromUrl(screening.movie!.trailers![0])"
              [tagGroups]="screening.movie.tagGroups!"
            ></kg-auto-trailer>
          </div>

          <kg-seat-grid
            class="content-relative"
            [screen]="screening.screen!"
            [screenId]="screening.screenId"
            [screeningId]="screening.id"
            [buyTimeExceeded]="!canBuyTicket"
            (selectedSeat)="onSeatSelect($event)"
          ></kg-seat-grid>

          <kg-tape [type]="TapeType.MOVIE" img="tape-movie"></kg-tape>
        </div>
      </div>
      <div class="reservation-tickets-panel">
        <kg-tickets
          (onSelect)="onTicketSelect($event)"
          (onApplyVoucher)="onApplyVoucher($event)"
          (onRemoveVoucher)="onRemoveVoucher($event)"
          [voucher]="voucherFromUrl"
        ></kg-tickets>
        <kg-sticky-summary
          [orderTickets]="orderTickets"
          [title]="'Reservation.GoNext'"
          (onButtonClick)="goToPaymentForm()"
        ></kg-sticky-summary>
      </div>
    </div>
  </div>
</div>

<ng-template #paymentStartedTemplate>
  <kg-alert
    *ngIf="paymentId"
    class="payment-started-alert"
    [message]="'Payment.PaymentStarted' | translate"
    [type]="AlertType.Warning"
  ></kg-alert>
</ng-template>

<ng-template #screeningStartedTemplate>
  <kg-alert
    *ngIf="canBuyTicket && showCanBuyTicketAlert"
    [type]="AlertType.Warning"
    message="{{ 'Screening.ScreeningHasStartedButCanBuy' | translate }}"
  ></kg-alert>
</ng-template>

<ng-template #screeningTimeExceededTemplate>
  <kg-alert
    *ngIf="!canBuyTicket"
    [type]="AlertType.Error"
    message="{{ 'Screening.ScreeningHasStartedButCantBuy' | translate }}"
  ></kg-alert>
</ng-template>
