import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '@services/language.service';
import { DateTime, DateTimeFormatOptions } from 'luxon';

@Pipe({
  name: 'LuxonDatePipe',
  standalone: true,
  pure: false,
})
export class LuxonDatePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}
  transform(date: string, format: DateTimeFormatOptions | Object): string {
    return DateTime.fromISO(date).toLocaleString(format, { locale: this.languageService.getCurrentLanguage() });
  }
}
