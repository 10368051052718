import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'kg-float-label',
  standalone: true,
  template: `
    <div class="float-label">
      <ng-content select="[nz-input]"></ng-content>
      <label class="label">{{ label }}</label>
      <ng-content select="[icon]"></ng-content>
    </div>
  `,
  styleUrls: ['./float-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatLabelComponent {
  @Input() label!: string;
  @Input() isVoucher: boolean = false;
}
