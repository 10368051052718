import { gql } from 'apollo-angular';

export const GetScreeningListForSelectDocument = gql`
  query getScreeningListForSelect($movieId: String!) {
    getScreeningListByMovieId(movieId: $movieId) {
      id
      screeningTimeFrom
    }
  }
`;
