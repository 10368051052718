import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ReservationState } from 'src/app/_store/reservation.state';

@Component({
  selector: 'kg-reservation-timer',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  template: `
    <div class="reservation-timer__container">
      <svg-icon src="assets/img/icons/timer.svg"></svg-icon>
      <span>{{ remainingTime }}</span>
    </div>
  `,
  styleUrls: ['./reservation-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationTimerComponent {
  @ViewSelectSnapshot(ReservationState.getRemainingTime) remainingTime!: number;
}
