import { SeatKindTypes, SeatOutput } from '@graphql/graphql-types';

export class ScreenSeat implements SeatOutput {
  colId?: string;
  groupId?: string;
  id?: string;
  kind?: SeatKindTypes;
  rowId?: string;
  symbol?: string;
  wheelchairSeat?: boolean;

  constructor(data: ScreenSeat | SeatOutput) {
    data ? Object.assign(this, data) : '';
  }
}
