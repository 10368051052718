<div
  class="seat-grid__container"
  [class.is-disabled]="loading$ | async"
  [class.is-payment-started]="paymentId"
  [class.buy-time-exceeded]="buyTimeExceeded"
>
  <div class="seat-grid__row" #parent *ngFor="let gridRow of seatGrid$ | async; first as isFirst">
    <div class="screen" *ngIf="isFirst" [style]="'width: ' + scrollWidth">
      <div class="screen-title">{{ 'Reservation.Screen' | translate }}</div>
      <img
        class="screen-img"
        [style]="'width: ' + scrollWidth"
        src="assets/img/screen.png"
        alt="{{ 'Reservation.Screen' | translate }}"
      />
    </div>
    <span
      *ngIf="gridRow && gridRow.length"
      class="seat-grid__row__legend seat-grid__row__legend_layout seat-grid__row__legend_appearance"
      [innerText]="gridRow[0].row.legend"
    ></span>

    <ng-container *ngFor="let gridItem of gridRow">
      <div class="seat-grid__col" *ngIf="!gridItem.isHidden">
        <span
          class="seat-grid__seat seat-grid-remove-trigger"
          [attr.data-id-first]="gridItem.seatWrapper.seats[0].id"
          [attr.data-id-second]="gridItem.seatWrapper.seats.length > 1 ? gridItem.seatWrapper.seats[1].id : null"
          (click)="onSeatSelected(gridItem)"
          [class.seat-grid__seat-selected]="gridItem.seatWrapper.isSelected"
          [class.seat-grid__seat-occupied]="gridItem.seatWrapper.isOccupied"
          [class.seat-grid__seat-blocked]="gridItem.seatWrapper.isBlocked"
        >
          <svg-icon [src]="gridItem.seatWrapper | seatIcon"></svg-icon>

          <kg-loader
            class="seat-loader"
            *ngIf="gridItem.seatWrapper.isLoading"
            [center]="true"
            [seatLoader]="true"
          ></kg-loader>
        </span>
      </div>
    </ng-container>
    <span
      *ngIf="gridRow && gridRow.length"
      class="seat-grid__row__legend seat-grid__row__legend_layout seat-grid__row__legend_appearance"
      [innerText]="gridRow[0].row.legend"
    ></span>
  </div>
  <div *ngIf="(seatGrid$ | async)?.length === 0 && !(loading$ | async)">
    <kg-alert
      [message]="'ApiErrors.' + ErrorMessage.SHOR_MAINTANCE_WORK | translate"
      [type]="AlertType.Error"
    ></kg-alert>
  </div>
</div>
