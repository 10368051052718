import { Pipe, PipeTransform } from '@angular/core';
import { Langauge } from '@enums/lang.enum';
import { Movie } from '@models/movie.model';
import { LocalStorageService } from '@services/local-storage.service';

@Pipe({
  name: 'movieTitle',
  standalone: true,
  pure: false,
})
export class MovieTitlePipe implements PipeTransform {
  constructor(private storage: LocalStorageService) {}
  transform(movie: Movie): string {
    switch (this.storage.getLang()) {
      case Langauge.EN:
        return movie.originalTitle ? movie.originalTitle : movie.title!;
      default:
        return movie.title!;
    }
  }
}
