import {
  BrightcoreVideoOutput,
  DistributorOutput,
  GenreOutput,
  MediaResourceOutput,
  MovieOutput,
  RatingOutput,
  TagGroupOutput,
} from '@graphql/graphql-types';

export class Movie implements MovieOutput {
  ageLongDescription?: string;
  banners?: MediaResourceOutput[];
  brightcoreVideos?: BrightcoreVideoOutput[];
  country?: string;
  description?: string;
  director?: string;
  distributor?: DistributorOutput;
  distributorId?: string;
  duration?: number;
  filmCast?: string;
  genres?: GenreOutput[];
  id?: string;
  imdbId?: string;
  imdbRating?: string;
  isForChildren?: boolean;
  originalLanguage?: string;
  originalTitle?: string;
  originalTitleLanguage?: string;
  pictures?: string[];
  posPosters?: string[];
  posters?: string[];
  premiereDate?: any;
  ratings?: RatingOutput[];
  releaseDate?: any;
  scenario?: string;
  shortDescription?: string;
  shortTitle?: string;
  tagGroups?: TagGroupOutput[];
  title?: string;
  trailers?: string[];
  type?: string;
  worldPremiereDate?: any;
  yearOfProduction?: string;

  constructor(data: Movie | MovieOutput) {
    data ? Object.assign(this, data) : '';
  }
}
