export enum ErrorMessage {
  UNAUTHORIZED = 'Unauthorized',
  INVALID_CREDENTIALS = 'Invalid credentials',
  INCORRECT_OLD_CREDENTIALS = 'Incorrect old password',
  EMAIL_ALREADY_EXISTS = 'Email already exists',
  ALREADY_EXISTS = 'Already exists',
  INCORRECT_INPUT_DATA = 'Bad Request Exception',
  INCORRECT_NEW_PASSWORD = 'New password cannot be same as old password',
  SHOR_MAINTANCE_WORK = 'Krótkie prace utrzymaniowe. Wróć niedługo, dziękujemy za zrozumienie',
  SCREENING_DELETED = 'Screening has been deleted',
  SCREENING_NOT_FOUND = 'Nie znaleziono seansu',
  ONE_OF_SEATS_RESERVED = 'One of the seats has been already reserved',
  VOUCHER_NOT_FOUND = 'Voucher not found',
  NOT_FOUND = 'Not found',
  VOUCHER_ALREADY_BEEN_USED = 'Voucher has already been used',
  CANNOT_USE_VOUCHER = 'Cannot use voucher',
  BASKET_IS_CLOSED = 'Basket is closed',
}
