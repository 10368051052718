import { isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { onError } from '@apollo/client/link/error';
import { Routes } from '@configs/routes';
import { ErrorMessage } from '@enums/error-messages.enum';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';

export const graphqlErrorMiddleware = (router: Router, n: NotificationService, t: TranslateService) => {
  return onError(({ response, operation }) => {
    if (response?.errors) {
      response?.errors.forEach((graphQLError: any) => {
        switch (graphQLError?.message) {
          case ErrorMessage.SHOR_MAINTANCE_WORK:
            n.onError(t.instant(`ApiErrors.${ErrorMessage.SHOR_MAINTANCE_WORK}`));
            response.errors = [];
            break;
          case ErrorMessage.SCREENING_DELETED:
            n.onError(t.instant(`ApiErrors.${ErrorMessage.SCREENING_DELETED}`));
            router.navigate([Routes.Screening]);
            response.errors = [];
            break;
          case ErrorMessage.ONE_OF_SEATS_RESERVED:
            n.onError(t.instant(`ApiErrors.${ErrorMessage.ONE_OF_SEATS_RESERVED}`));
            break;
          case ErrorMessage.VOUCHER_NOT_FOUND:
            n.onError(t.instant(`ApiErrors.${ErrorMessage.VOUCHER_NOT_FOUND}`));
            break;
          case ErrorMessage.VOUCHER_ALREADY_BEEN_USED:
            n.onError(t.instant(`Vouchers.${ErrorMessage.VOUCHER_ALREADY_BEEN_USED}`));
            break;
          case ErrorMessage.CANNOT_USE_VOUCHER:
            n.onError(t.instant(`Vouchers.${ErrorMessage.CANNOT_USE_VOUCHER}`));
            break;
          case ErrorMessage.BASKET_IS_CLOSED:
            n.onError(t.instant(`ApiErrors.${ErrorMessage.BASKET_IS_CLOSED}`));
            break;
          default:
            break;
        }
        if (isDevMode()) printError(graphQLError);
      });
    }
  });
};

function printError(graphQLError: any): void {
  if (isDevMode()) console.error(`[Graphql error]: ${JSON.stringify(graphQLError)}`);
}

function printUnauthenticatedError(): void {
  if (isDevMode()) console.error('UNAUTHENTICATED ERROR');
}
