import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertType } from '@enums/alert-type.enum';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'kg-alert',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  template: `
    <div class="notification" [ngClass]="type">
      <div class="notification-gradient"></div>
      <div class="notification-body">
        <ng-container *ngIf="!customContent; else content">
          <div class="notification-icon">
            <svg-icon [src]="'assets/img/icons/' + type + '.svg'" *ngIf="type !== AlertType.Primary"></svg-icon>
          </div>
          <div class="notification-message">{{ message }}</div>
        </ng-container>
        <ng-template #content>
          <ng-content select="[custom-content]"></ng-content>
        </ng-template>
      </div>
      <div class="notification-close" *ngIf="closable" (click)="onClose.emit()">
        <svg-icon src="assets/img/icons/toast-close.svg"></svg-icon>
      </div>
    </div>
  `,
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  @Input() type!: AlertType;
  @Input() message!: string;
  @Input() closable = false;
  @Input() customContent = false;
  @Output() onClose: EventEmitter<null> = new EventEmitter();
  AlertType = AlertType;
  constructor() {}
}
