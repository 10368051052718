import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Config } from '@configs/config';
import { ScreeningItemOutput } from '@graphql/graphql-types';
import { Ticket } from '@models/ticket.model';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SelectSnapshot, ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Actions, ofActionCompleted } from '@ngxs/store';
import { CustomCurrencyPipe } from '@pipes/custom-currency.pipe';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { Observable, takeUntil } from 'rxjs';
import { UpdateTickets } from 'src/app/_store/actions/update-tickets.action';
import { AppliedVoucher, ReservationOrder } from 'src/app/_store/models/reservation.model';
import { ReservationState } from 'src/app/_store/reservation.state';
import { CardSimpleComponent } from '../card-simple/card-simple.component';
import { DestroyableComponent } from '../destroyable.component';
import { TooltipComponent } from '../tooltip/tooltip.component';

@Component({
  selector: 'kg-ticket',
  standalone: true,
  imports: [
    CommonModule,
    CardSimpleComponent,
    AngularSvgIconModule,
    TranslateModule,
    NzRadioModule,
    TooltipComponent,
    FormsModule,
    CustomCurrencyPipe,
    NgSelectModule,
  ],
  template: `
    <div class="ticket">
      <kg-card-simple>
        <div class="ticket-header">
          <div class="ticket-header-left">
            <div class="ticket-place">
              <span class="ticket-place-number">{{ row }}</span>
              <span class="ticket-place-text">{{ 'Tickets.Row' | translate }}</span>
              <span class="ticket-place-number">{{ seat }}</span>
              <span class="ticket-place-text">{{ 'Tickets.Place' | translate }}</span>
            </div>
          </div>
          <div class="ticket-header-right">
            <button
              class="btn btn-fab btn-fab-md btn-fab-transparent"
              [disabled]="(disabled$ | async) || paymentId"
              (click)="onTicketRemove(ticketGroup[0])"
            >
              <svg-icon class="remove-icon" src="assets/img/icons/remove.svg"></svg-icon>
            </button>
          </div>
        </div>
        <div class="voucher-info" *ngIf="ticketGroup[0].hasVoucher; else ticketToggler">
          <svg-icon src="assets/img/icons/tick-circle.svg"></svg-icon>
          <span class="voucher-info-text">
            {{ 'Vouchers.VoucherAppliedInfo' | translate }}
          </span>
        </div>

        <ng-template #ticketToggler>
          <div class="voucher-select" *ngIf="ticketGroup.length > 2; else buttonToggler">
            <div
              class="select select-wrapper"
              [class.is-reduced]="selectedTicketName === 'Ulgowy' || selectedTicketName === 'ReducedTicket'"
            >
              <ng-select
                [(ngModel)]="currentTicketId"
                (ngModelChange)="onTicketSelect($event)"
                [items]="ticketGroup"
                bindLabel="name"
                bindValue="id"
                [searchable]="false"
                [clearable]="false"
                [disabled]="(disabled$ | async) || paymentId"
                dropdownPosition="bottom"
              >
                <ng-template ng-option-tmp let-item="item">
                  {{
                    item.name! === 'NormalTicket' || item.name! === 'ReducedTicket'
                      ? ('Tickets.' + item.name! | translate)
                      : item.name!
                  }},
                  {{ item.priceWithMandatoryExtraFees! | customCurrency }}
                </ng-template>
                <ng-template ng-label-tmp let-m="item">
                  <div class="select-placeholder">{{ 'Reservation.SelectedTicket' | translate }}</div>
                  <div class="select-value">
                    {{
                      m.name! === 'NormalTicket' || m.name! === 'ReducedTicket'
                        ? ('Tickets.' + m.name! | translate)
                        : m.name!
                    }},
                    {{ m.priceWithMandatoryExtraFees! | customCurrency }}
                  </div>
                </ng-template>
              </ng-select>
              <span
                class="tooltip-wrapper"
                *ngIf="selectedTicketName === 'Ulgowy' || selectedTicketName === 'ReducedTicket'"
              >
                <svg-icon src="assets/img/icons/info-circle.svg"> </svg-icon>
                <kg-tooltip [tooltipText]="'Tickets.ReducedTicketInfo' | translate"></kg-tooltip>
              </span>
            </div>
          </div>
          <ng-template #buttonToggler>
            <div class="ticket-toggler">
              <nz-radio-group [(ngModel)]="currentTicketId" (ngModelChange)="onTicketSelect($event)">
                <label nz-radio [nzValue]="ticketGroup[0].id" [nzDisabled]="(disabled$ | async) || paymentId">
                  <button class="btn btn-primary btn-block btn-mdl">
                    {{
                      ticketGroup[0].name! === 'NormalTicket' || ticketGroup[0].name! === 'ReducedTicket'
                        ? ('Tickets.' + ticketGroup[0].name! | translate)
                        : ticketGroup[0].name!
                    }},
                    {{ ticketGroup[0].priceWithMandatoryExtraFees! | customCurrency }}
                  </button>
                </label>
                <label
                  nz-radio
                  [nzValue]="ticketGroup[1].id"
                  [nzDisabled]="(disabled$ | async) || paymentId"
                  *ngIf="ticketGroup.length > 1"
                >
                  <button class="btn btn-primary btn-block btn-mdl">
                    {{
                      ticketGroup[1].name! === 'NormalTicket' || ticketGroup[1].name! === 'ReducedTicket'
                        ? ('Tickets.' + ticketGroup[1].name! | translate)
                        : ticketGroup[1].name!
                    }},
                    {{ ticketGroup[1].priceWithMandatoryExtraFees! | customCurrency }}
                    <span class="tooltip-wrapper">
                      <svg-icon src="assets/img/icons/info-circle.svg"> </svg-icon>
                      <kg-tooltip [tooltipText]="'Tickets.ReducedTicketInfo' | translate"></kg-tooltip>
                    </span>
                  </button>
                </label>
              </nz-radio-group>
            </div>
          </ng-template>
        </ng-template>
      </kg-card-simple>
    </div>
  `,
  styleUrls: ['./ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketComponent extends DestroyableComponent implements OnInit {
  @SelectSnapshot(ReservationState.getReservationOrder) order!: ReservationOrder;
  @SelectSnapshot(ReservationState.getVouchers) vouchers!: AppliedVoucher[];
  @ViewSelectSnapshot(ReservationState.getPaymentId) paymentId!: string;

  @Input() ticketGroup!: Ticket[];
  @Input() row!: string;
  @Input() seat!: string;
  @Input() hasVoucher: boolean = false;
  @Input() disabled$!: Observable<boolean>;

  @Output() onRemoveTicketWithVoucher: EventEmitter<null> = new EventEmitter();
  @Output() onSelect: EventEmitter<Ticket> = new EventEmitter();

  currentTicketId = '';
  Config = Config;

  get selectedTicketName(): string | undefined {
    return this.ticketGroup.find((ticket) => ticket.id === this.currentTicketId)?.name;
  }

  constructor(private modal: NzModalService, private actions$: Actions, private t: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.checkSelectedTicket();

    this.actions$.pipe(ofActionCompleted(UpdateTickets), takeUntil(this.destroy$)).subscribe(() => {
      this.checkSelectedTicket();
    });
  }

  checkSelectedTicket(): void {
    if (!this.order) {
      return;
    }
    this.order.screeningItems.forEach((item: ScreeningItemOutput) => {
      this.ticketGroup.forEach((ticket: Ticket) => {
        if (ticket.screeningItemId === item.id && ticket.id === item.ticketId) {
          this.currentTicketId = item.ticketId!;
        }
      });
    });
  }

  onTicketSelect(ticketId: string): void {
    const ticket = this.ticketGroup.find((t) => t.id === ticketId)!;
    this.onSelect.emit(ticket);
  }

  onTicketRemove(ticket: Ticket): void {
    if (ticket.hasVoucher) {
      this.showDeleteTicketConfirm(ticket);
    } else {
      this.removeTicket(ticket);
    }
  }

  removeTicket(ticket: Ticket): void {
    const seatId = this.order.screeningItems.find((item) => item.id === ticket.screeningItemId)?.seatId;
    const seats = document.querySelectorAll('.seat-grid-remove-trigger');
    const numSeats = seats.length;
    for (let i = 0; i < numSeats; i++) {
      const seat = seats[i] as HTMLSpanElement;
      const seatIdFirst = seat.getAttribute('data-id-first');
      const seatIdSecond = seat.getAttribute('data-id-second');

      if (seatIdFirst === seatId || seatIdSecond === seatId) {
        seat.click();
      }
    }
  }

  showDeleteTicketConfirm(ticket: Ticket): void {
    this.modal.confirm({
      nzTitle: this.t.instant('Tickets.AreYouSureToRemove'),
      nzContent: this.t.instant('Tickets.ThisTicketHasAVoucher'),
      nzOkText: this.t.instant('Tickets.Remove'),
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        this.removeTicket(ticket);
        this.onRemoveTicketWithVoucher.emit();
      },
      nzCancelText: this.t.instant('Tickets.Discard'),
    });
  }
}
