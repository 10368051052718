import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralize',
  standalone: true,
})
export class PluralizePipe implements PipeTransform {
  transform(value: number): string {
    const lastTwoDigits = value % 100;

    if (lastTwoDigits === 1) {
      return 'Tickets.Ticket';
    } else if (lastTwoDigits > 1 && lastTwoDigits < 5) {
      return 'Tickets.Tickets';
    } else {
      return 'Tickets.OfTickets';
    }
  }
}
