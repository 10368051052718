import { Config } from '@configs/config';
import { ScreeningOutput } from '@graphql/graphql-types';
import { Ticket } from '@models/ticket.model';
import { TranslateService } from '@ngx-translate/core';
import { DateTime, Duration } from 'luxon';

export default class Utils {
  static getVideoIdFromUrl(url: string): string {
    const searchParams = new URLSearchParams(new URL(url).search);
    return searchParams.get('v') || '';
  }
  static getMovieDuration(value: number): string {
    const duration = Duration.fromObject({ minutes: value });
    return duration.toFormat(Config.MOVIE_DURATION_FORMAT);
  }
  static isMobileSafari() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    const isIOSChrome =
      !!window.navigator.userAgent.match('CriOS') ||
      (!!window.navigator.userAgent.match('Chrome') && !!window.navigator.vendor.match(/Google Inc./));
    if (isIOS && !isIOSChrome) {
      return true;
    }
    return false;
  }
  static sortByScreeningTimeFrom(list: ScreeningOutput[]): ScreeningOutput[] {
    return list.sort((a, b) => new Date(a.screeningTimeFrom).getTime() - new Date(b.screeningTimeFrom).getTime());
  }
  static canBuyTicket(saleTimeTo: string): boolean {
    const currentDateTime = DateTime.local();
    const saleTimeToIso = DateTime.fromISO(saleTimeTo);
    return saleTimeToIso > currentDateTime;
  }
  static showBuyTicketAlert(screeningTimeFrom: string, saleTimeTo: string): boolean {
    const currentDateTime = DateTime.local();
    const screeningTimeFromIso = DateTime.fromISO(screeningTimeFrom);
    const saleTimeToIso = DateTime.fromISO(saleTimeTo);
    return currentDateTime >= screeningTimeFromIso && currentDateTime <= saleTimeToIso;
  }
  static macosOrIos(): boolean {
    return this.getOS() === 'macos' || this.getOS() === 'ios';
  }
  static getUserAgent(): string {
    return window.navigator.userAgent;
  }
  static getOS() {
    let userAgent = window.navigator.userAgent.toLowerCase(),
      macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
      windowsPlatforms = /(win32|win64|windows|wince)/i,
      iosPlatforms = /(iphone|ipad|ipod)/i,
      os = null;

    if (macosPlatforms.test(userAgent)) {
      os = 'macos';
    } else if (iosPlatforms.test(userAgent)) {
      os = 'ios';
    } else if (windowsPlatforms.test(userAgent)) {
      os = 'windows';
    } else if (/android/.test(userAgent)) {
      os = 'android';
    } else if (!os && /linux/.test(userAgent)) {
      os = 'linux';
    }
    return os;
  }
  static groupTickets(tickets: Ticket[]): Ticket[][] {
    // senior ticket ID - remove this ticket type from positive
    // waiting for client to remove it
    const tempSeniorTicketId = 'b6e8bebf-8573-4935-86c5-5f77ece159e4';
    const groups = new Map();
    tickets.forEach((ticket) => {
      const { screeningItemId, id } = ticket;
      if (id !== tempSeniorTicketId) {
        const group = groups.get(screeningItemId) || [];
        group.push(ticket);
        groups.set(screeningItemId, group);
      }
    });
    return Array.from(groups.values());
  }
  static getReservationTimeDiff(date: string): number {
    const targetDate = DateTime.fromISO(date);
    const now = DateTime.local();
    return targetDate.diff(now).valueOf();
  }
  static hasTranslation(key: string, t: TranslateService): boolean {
    const translation = t.instant(key);
    return translation !== key && translation !== '';
  }
}
