import { DisplayMode } from '@graphql/graphql-types';
import { SalesMode } from '@graphql/graphql-types';
import { GroupSecondOutput } from '@graphql/graphql-types';

export class ScreenGroup implements GroupSecondOutput {
  color?: string;
  description?: string;
  displayMode?: DisplayMode;
  ico?: string;
  id?: string;
  name?: string;
  salesMode?: SalesMode;
  seatIds?: string[];
  type?: string;

  constructor(data: ScreenGroup | GroupSecondOutput) {
    data ? Object.assign(this, data) : '';
  }
}
