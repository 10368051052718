import { CreateOrderFragment } from '@graphql/fragments/reservation/create-order.fragment';
import { gql } from 'apollo-angular';

export const CreateOrderDocument = gql`
  mutation createOrder($orderEntries: [OrderEntryInput!]!) {
    createOrder(orderEntries: $orderEntries) {
      ...CreateOrder
    }
  }
  ${CreateOrderFragment}
`;
