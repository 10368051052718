import { Injectable } from '@angular/core';
import { GetTechnicalStatus } from '@graphql/queries/movie/get-technical-status.query';
import { GetTechnicalBreakStatusQuery } from '@graphql/queries/movie/get-technical-status.query.generated';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TechnicalStatusGraphqlService {
  constructor(private apollo: Apollo) {}

  getTechnicalBreakStatus() {
    return this.apollo
      .query<GetTechnicalBreakStatusQuery>({
        query: GetTechnicalStatus,
        fetchPolicy: 'no-cache',
      })
      .pipe(map((res) => res.data));
  }
}
