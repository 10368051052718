import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'kg-card-simple',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="card-simple">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./card-simple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardSimpleComponent {}
