import { Pipe, PipeTransform } from '@angular/core';
import { SeatWrapper } from '@interfaces/seat-wrapper.interface';

const CHAIR_SINGLE = 'chair_single';
const CHAIR_DOUBLE = 'chair_double';

@Pipe({
  name: 'seatIcon',
  standalone: true,
})
export class SeatIconPipe implements PipeTransform {
  transform(seatWrapper: SeatWrapper): string {
    const iconName = seatWrapper.group ? CHAIR_DOUBLE : CHAIR_SINGLE;
    return `assets/img/icons/${iconName}.svg`;
  }
}
