import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Routes } from '@configs/routes';
import { AlertType } from '@enums/alert-type.enum';
import { Langauge } from '@enums/lang.enum';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Store } from '@ngxs/store';
import { MovieTitlePipe } from '@pipes/movie-title.pipe';
import { ScreeningDatePipe } from '@pipes/screening-date.pipe';
import { LocalStorageService } from '@services/local-storage.service';
import { ReservationService } from '@services/reservation.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ReservationState } from 'src/app/_store/reservation.state';
import { AlertComponent } from '../alert/alert.component';
import { CardWithIconComponent } from '../card-with-icon/card-with-icon.component';
import { ReservationTimerComponent } from '../reservation-timer/reservation-timer.component';

@Component({
  selector: 'kg-back-to-reservation',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    AlertComponent,
    CardWithIconComponent,
    AngularSvgIconModule,
    ReservationTimerComponent,
    MovieTitlePipe,
    ScreeningDatePipe,
  ],
  template: `
    <div class="back-to-reservation">
      <kg-alert [type]="AlertType.Primary" [closable]="true" [customContent]="true" (onClose)="onClose()">
        <div custom-content>
          <div class="back-to-reservation-title">
            {{ movieTitle }}
          </div>
          <div class="back-to-reservation-screening">
            <kg-card-iconed>
              <svg-icon icon src="assets/img/icons/calendar.svg"></svg-icon>
              <div title>{{ screeningTimeFrom | screeningDatePipe }}</div>
              <div body>
                {{ 'Reservation.Room' | translate }} N<span class="fixed-underline">o</span>{{ roomNumber }}
              </div>
            </kg-card-iconed>
          </div>
          <div class="back-to-reservation-button">
            <button class="btn btn-primary" (click)="back()">
              {{ 'Reservation.BackToReservation' | translate }}
            </button>
            <kg-reservation-timer></kg-reservation-timer>
          </div>
        </div>
      </kg-alert>
    </div>
  `,
  styleUrls: ['./back-to-reservation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackToReservationComponent implements OnInit, OnDestroy {
  @ViewSelectSnapshot(ReservationState.getScreeningTime) screeningTimeFrom!: string;
  @ViewSelectSnapshot(ReservationState.getMovieTitle) movieTitles!: { movieTitle: string; movieOriginalTitle: string };
  @ViewSelectSnapshot(ReservationState.getScreenRoomNumber) roomNumber!: number;
  AlertType = AlertType;

  constructor(
    private store: Store,
    private router: Router,
    private reservationService: ReservationService,
    private t: TranslateService,
    private storage: LocalStorageService,
  ) {}

  get movieTitle(): string {
    switch (this.storage.getLang()) {
      case Langauge.EN:
        return this.movieTitles.movieOriginalTitle ? this.movieTitles.movieOriginalTitle : this.movieTitles.movieTitle;
      default:
        return this.movieTitles.movieTitle;
    }
  }

  ngOnInit(): void {
    document.body.querySelector('kg-root')?.classList.add('show-btr');
  }

  back(): void {
    const store = this.store.snapshot();
    this.router.navigate([`${Routes.Reservation.Places}/${store.reservation.order.screeningId}`]);
  }

  onClose(): void {
    const store = this.store.snapshot();
    this.reservationService.showCancelReservationConfirm(
      store.reservation.order.screeningId,
      false,
      this.t.instant('Reservation.DiscardReservation'),
    );
  }

  ngOnDestroy(): void {
    document.body.querySelector('kg-root')?.classList.remove('show-btr');
  }
}
