import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Routes } from '@configs/routes';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { ReservationService } from '@services/reservation.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ResetReservation } from 'src/app/_store/actions/reset-reservation.action';
import { UpdateSession } from 'src/app/_store/actions/update-session.action';

@Component({
  selector: 'kg-end-session',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, TranslateModule],
  template: `
    <div class="end-session">
      <div class="end-session-icon">
        <svg-icon src="assets/img/icons/end-session.svg"></svg-icon>
      </div>
      <h1 class="end-session-title">{{ 'Reservation.EndSessionTitle' | translate }}</h1>
      <div class="end-session-action">
        <button class="btn btn-primary" (click)="backToRepetoire()">
          {{ 'Reservation.BackToRepertoire' | translate }}
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./end-session.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EndSessionComponent implements OnInit, OnDestroy {
  constructor(private router: Router, private store: Store, private reservationService: ReservationService) {}

  backToRepetoire(): void {
    const store = this.store.snapshot();
    this.reservationService.deleteOrder(store.reservation.order.orderId).subscribe({
      next: () => {
        this.store.dispatch(new ResetReservation());
        this.store.dispatch(new UpdateSession(false));
      },
      error: () => {},
      complete: () => {
        this.router.navigate([Routes.Screening]);
      },
    });
  }

  ngOnInit(): void {
    document.body.classList.add('scroll-disabled');
  }

  ngOnDestroy(): void {
    document.body.classList.remove('scroll-disabled');
  }
}
