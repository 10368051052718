export enum FormControls {
  UserFirstName = 'userFirstName',
  UserLastName = 'userLastName',
  UserEmail = 'userEmail',
  UserPhone = 'userPhone',
  Prefix = 'prefix',
  RulesConsent = 'rulesConsent',
  SmsConsent = 'smsConsent',
  EmailConsent = 'emailConsent',
  CheckAll = 'checkAll',
  VoucherNumber = 'voucherNumber',
  TaxId = 'taxId',
}
