import { gql } from 'apollo-angular';

export const EditOrderFragment = gql`
  fragment EditOrder on OrderOutput {
    id
    dateTimeToLive
    screeningItems {
      id
      seatId
      screeningId
      ticketId
      seat
      row
      price
      voucherNumber
      name
      printoutName
    }
  }
`;
