import {
  BasketCardItemOutput,
  BasketCardTypeItemOutput,
  DefaultExtraFeeOutput,
  OrderFbBasketItemOutput,
  OrderMembershipPriceOutput,
  OrderOutput,
  OrderStatus,
  OrderVoucherItemOutput,
  PaymentMethodOutput,
  ScreeningItemOutput,
  TotalValuePerTaxRatesOutput,
} from '@graphql/graphql-types';

export class Order implements OrderOutput {
  bookingId?: string;
  cardId?: string;
  cardItems?: BasketCardItemOutput[];
  cardTypeItems?: BasketCardTypeItemOutput[];
  dateEntry?: Date;
  dateTimeToLive?: Date;
  defaultExtraFees?: DefaultExtraFeeOutput[];
  defaultPriceLevelTotalValue?: number;
  externalUserId?: number;
  fbItems?: OrderFbBasketItemOutput[];
  id?: string;
  membershipPrices?: OrderMembershipPriceOutput[];
  orderNumber?: string;
  paymentMethods?: PaymentMethodOutput[];
  screeningItems?: ScreeningItemOutput[];
  status?: OrderStatus;
  taxId?: string;
  totalEarnedPoints?: number;
  totalNetValue?: number;
  totalRedemptionPoints?: string;
  totalTaxValue?: number;
  totalValue?: number;
  totalValuePerTaxRates?: TotalValuePerTaxRatesOutput[];
  userEmail?: string;
  userFirstName?: string;
  userLastName?: string;
  userPhone?: string;
  valueToPay?: number;
  voucherItems?: OrderVoucherItemOutput[];

  constructor(data: Order | OrderOutput) {
    data ? Object.assign(this, data) : '';
  }
}
