import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

const DEFAULT_TOAST_OPTIONS: Partial<IndividualConfig> = {
  closeButton: false,
  positionClass: 'toast-top-center',
  tapToDismiss: true,
};

export enum ToastClass {
  SUCCESS = 'toast-success',
  WARNING = 'toast-warning',
  ERROR = 'toast-error',
}

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private toastr: ToastrService) {}

  onSuccess(message: string): void {
    this.toastr.success(message, undefined, { ...DEFAULT_TOAST_OPTIONS, ...{ toastClass: ToastClass.SUCCESS } });
  }

  onWarning(message: string): void {
    this.toastr.warning(message, undefined, { ...DEFAULT_TOAST_OPTIONS, ...{ toastClass: ToastClass.WARNING } });
  }

  onError(message: string): void {
    this.toastr.error(message, undefined, { ...DEFAULT_TOAST_OPTIONS, ...{ toastClass: ToastClass.ERROR } });
  }
}
