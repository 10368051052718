import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageKey } from '@enums/storage-key.enum';
import { LocalStorageService } from './local-storage.service';
import { User } from '@models/user.model';

@Injectable({ providedIn: 'root' })
export class UserService {
  private readonly _userChangedEvents$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly userChangedEvents$: Observable<boolean> = this._userChangedEvents$.asObservable();

  private readonly _currentUser$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  readonly currentUser$: Observable<User | null> = this._currentUser$.asObservable();

  constructor(private readonly storageService: LocalStorageService) {}

  get user(): User | null {
    const user = this.storageService.get(StorageKey.USER);
    return user ? new User(JSON.parse(user)) : null;
  }

  set user(user: User | null) {
    if (!user) return;
    const u = new User(user);
    this.storageService.put(StorageKey.USER, JSON.stringify(u));
    this._userChangedEvents$.next(true);
    this._currentUser$.next(u);
  }

  clearUser(): void {
    this.user = null;
  }
}
